import { SectionType } from '../../libs/store/actions/WebAnalytics/TrafficAnalyticsActions';

/**
 * Constants for CBO Web Analytics.
 */
export const TrafficAnalysisConstants = {
  /**
   * Title for the active/inactive chart.
   */
  activeInactiveChartTitle: 'Most Viewed Cloud Assessments',

  /**
   * Color for the traffic analysis chart.
   */
  trafficAnalysisChartColor: ['#e0eee8'],

  /**
   * Colors for the active/inactive chart.
   */
  activeInactiveChartColor: ['#19a3a9', '#ff8f20'],

  /**
   * Colors for the cloud provider analytics chart.
   */
  cloudProviderAnalyticsColor: ['#5f91d8', '#ffb271', '#a9e68e', '#1b9cc8'],

  /**
   * Title for the chart.
   */
  chartTitle: 'Most Visited Menu Modules',

  /**
   * Filters for the chart.
   */
  chartFilters: ['Today', 'This Week', 'This Month'],

  /**
   * Categories for the active/inactive graph.
   */
  activeInactiveGraphCategories: [
    'Cloud Foundation Governance Assessment',
    'Cloud Suitablity & Placement',
    'Ops Model Assessment',
    'Cloud Spend Optimization',
    'Cloud Tech Value Benifit Realization',
    'Core Infrastructure Migration',
    'Data Migration',
    'Application On-Boarding',
  ],

  /**
   * Data for the first series of the active/inactive graph.
   */
  activeInactiveData1: [6, 5, 3, 4, 8, 7, 7, 7],

  /**
   * Data for the second series of the active/inactive graph.
   */
  activeInactiveData2: [3, 2, 4, 2, 3, 4, 4, 3],

  /**
   * Header values for the chart.
   */
  headerValues: ['Ranks', 'Sections', 'No. of Visits'],

  /**
   * Title for the most viewed architecture designs.
   */
  mostViewedTitle: 'Mostly Viewed Architecture Designs',

  /**
   * Filters for the most viewed architecture designs.
   */
  mostViewedFilters: ['Foundational', 'Workload', 'Application'],

  /**
   * Title for the cloud provider analytics.
   */
  cloudAnalyticsTitle: 'Cloud Provider Analytics',

  /**
   * Hashtag symbol.
   */
  hashtag: '#',

  /**
   * Title for the loading state.
   */
  loadingTitle: 'Fetching Charts ...',

  /**
   * Filters for the period.
   */
  periodFilter: ['Last 30 Days', '3 months', '6 months', '1 Year'],

  /**
   * Filters for the journey.
   */
  JourneyFilter: ['Migrate to Cloud', 'Modernize to Cloud', 'Modernize on prem to on prem'],
};
export const capabilitiesColorMapper = (value: string): string => {
  switch (value) {
    case 'Assess':
      return '#F8CE46';
    case 'Design':
      return '#7C4DFF';
    case 'Build':
      return '#A9E68E';
    case 'Migrate':
      return '#5F91D8';
    case 'Modernize':
      return '#FFB271';
    case 'Operate':
      return '#1B9CC8';
    case 'Test':
      return '#0297A8';
    default:
      return '#FFFFF';
  }
};
export const WebAnalyticsLabels = {
  peopleVisited: 'People Visited to the site',
  chartFilters: ['Daily', 'Weekly', 'Monthly'],
  activeUserTitle: 'Active Users on the Tool',
  areaChartTitle: ['Visits', 'Page Views', 'Average Page Visits', 'Bounce Rate'],
  areaChartColor: '#FFCB70',
  areaChartFillColor: '#FFF7EA',
  newUsersVsReturningUsers: 'New Users vs Returning Users',
  chart2Title: 'Usage Levels Role Vs Department',
  chart3Title: 'Cloud Environments Status Analytics',
  chart1Colors: ['#414FB7', '#19A3A9'],
  chart2Colors: ['#F5C01A', '#5F91D8', '#19A3A9'],
  chart3Colors: ['#19A3A9', '#EC407A', '#DADADA'],
};
export const periodYearLabel = 'FY25 Periods';

export const overallSummaryLabels = {
  overallSummary: 'Overall Summary',
  pursuits: 'Pursuits',
  engagements: 'Engagements',
};

export const pursuitsEngAccumulativeLabels = {
  pursuitHeaderTitle: 'Pursuits and Accumulative Pursuits',
  engheaderTitle: 'Engagements and Accumulative Engagements',
  periodWiseLabel: 'Period Wise',
  quarterWiseLabel: 'Quarter Wise',
  periodWise: 'periodwise',
  quarterWise: 'quarterwise',
  periodLabel: 'Periods',
  quarterLabel: 'Quarters',
  year: 'Year',
  All: 'All',
};
