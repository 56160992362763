import { Reducer } from 'redux';
import {
  EngagementSummaryAction,
  EngagementSummaryActionTypes,
} from '../../actions/cbo/EngagementSummaryAction';
import IEngagementSummaryState from '../../state/cbo/EngagementSummaryState';
import {
  IEngagementListForAdminRequestPayload,
  IEngagementListForAdminResponse,
  IEngagementResponse,
} from '../../../models/cbo/EngagementModels';
export const initialState: IEngagementSummaryState = {
  engagementList: [],
  pursuitList: [],
  error: '',
  loading: false,
  enagagementListForAdminLoading: false,
  isDeleteEngSuccessful: false,
  enagagementListForAdmin: {} as IEngagementListForAdminResponse,
  pursuitListForAdmin: {} as IEngagementListForAdminResponse,
  allPursuitEngagementList: {} as IEngagementListForAdminResponse,
  allPurusitEngagementListCompleted: true,
};

export const engagementSummaryReducer: Reducer<IEngagementSummaryState> = (
  state = initialState,
  action: EngagementSummaryAction,
) => {
  switch (action.type) {
    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_STARTED:
      return {
        ...state,
        loading: true,
      };
    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_SUCCESS:
      if (action?.flag?.isEngagement) {
        return {
          ...state,
          engagementList: action.data as IEngagementResponse,
          loading: false,
        };
      } else {
        return {
          ...state,
          pursuitList: action.data as IEngagementResponse,
          loading: false,
        };
      }
    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_STARTED:
      if ((action?.data as IEngagementListForAdminRequestPayload)?.isExport) {
        return {
          ...state,
          allPurusitEngagementListCompleted: false,
        };
      }
      return {
        ...state,
        enagagementListForAdminLoading: true,
      };
    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_SUCCESS:
      if (action?.flag?.isExport) {
        return {
          ...state,
          allPursuitEngagementList: action.data as IEngagementListForAdminResponse,
          allPurusitEngagementListCompleted: true,
        };
      } else {
        if (action?.flag?.isEngagement) {
          return {
            ...state,
            enagagementListForAdminLoading: false,
            enagagementListForAdmin: action.data as IEngagementListForAdminResponse,
          };
        } else {
          return {
            ...state,
            enagagementListForAdminLoading: false,
            pursuitListForAdmin: action.data as IEngagementListForAdminResponse,
          };
        }
      }

    case EngagementSummaryActionTypes.GET_ENGAGEMENT_SUMMARY_FOR_ADMIN_ERROR:
      if (action?.flag?.isExport) {
        return {
          ...state,
          allPurusitEngagementListCompleted: true,
          error: action.data as Error,
        };
      }
      return {
        ...state,
        enagagementListForAdminLoading: false,
        error: action.data as Error,
      };
    case EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_STARTED:
      return {
        ...state,
        isDeleteEngSuccessful: false,
        loading: true,
      };
    case EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_SUCCESS:
      return {
        ...state,
        isDeleteEngSuccessful: true,
        loading: false,
      };
    case EngagementSummaryActionTypes.DELETE_ENGAGEMENTCONFIG_DATA_ERROR:
      return {
        ...state,
        loading: false,
        isDeleteEngSuccessful: false,
        error: action.data as Error,
      };
    case EngagementSummaryActionTypes.RESET_DELETE_ENGAGEMENT_SUCCESSFULL:
      return {
        ...state,
        isDeleteEngSuccessful: false,
      };
    default:
      return state;
  }
};
