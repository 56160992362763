import { IConfigurePayload } from '../../../models/cbo/EngagementModels';
import { IFeatureUpdatePayload } from '../../../models/cbo/EngagementSummaryGraphModel';
import {
  ICapabilityReorderPayload,
  IViewSummaryResponsePayload,
} from '../../../store/state/cbo/CboViewSummaryState';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class CboViewSummaryService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);

  public postCapabilityOrder(
    body: ICapabilityReorderPayload,
  ): Promise<ICapabilityReorderPayload> | ICapabilityReorderPayload {
    const route: string[] = [`${API_ROUTE_CBO.POST_CAPABILITY_ORDER}`];
    return this.cboService.post({ route: route }, body);
  }

  public patchFeaturesUpdate(
    body: IFeatureUpdatePayload,
  ): Promise<IFeatureUpdatePayload> | IFeatureUpdatePayload {
    const route: string[] = [
      `${body.isEngagement ? API_ROUTE_CBO.ENGAGEMENT_FEATURES : API_ROUTE_CBO.FEATURES}`,
    ];
    return this.cboService.patch({ route: route }, body);
  }
}

const cboViewSummaryService = new CboViewSummaryService();
export default cboViewSummaryService;
