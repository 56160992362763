import ColorScale from 'color-scales';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import {
  Environment,
  localhostEnvironments,
  CBOdevEnvironments,
  CBOprodEnvironments,
} from '../../AppConstants';
import { AssessmentDetailsColor } from '../../containers/OpsModelAssessment/OpsModelAssessmentReportsConstants';
import { IUploadFileBody, LandingZoneStatus } from '../models/BuildDeployModels';
import { storage, STORAGE_CONSTANTS } from '../services/LocalStorage';
import { store } from '../store';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { IReportConfig } from '../models/cbo/SmartStartModel';

type IDecodedToken = {
  auth_time: number;
  client_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
  version: number;
};

export const logOut = () => {
  const authTokenBasic = storage.getItem(STORAGE_CONSTANTS.authTokenBasic);
  storage.clearAll();
  storage.setItem(STORAGE_CONSTANTS.authTokenBasic, authTokenBasic!);
  store.dispatch({ type: 'LOG_OUT' });
  window.open('/#/login', '_self');
};

export const dollarFormatter = (value: number) => `$${value.toFixed(2)}`;

export const getFormattedTimestamp = (dateTime: string) => {
  const formattedDateTime = new Date(dateTime);
  return formattedDateTime.toString().split('GMT')[0];
};

export const getEnv = () => {
  if (CBOdevEnvironments.includes(window.location.hostname)) return Environment.DEV;
  if (localhostEnvironments.includes(window.location.hostname)) return Environment.LOCALHOST;
  if (CBOprodEnvironments.includes(window.location.hostname)) {
    return Environment.PROD;
  } else return Environment.PROD;
};

export const isAuthenticated = (): boolean => {
  const accessToken = storage.getItem(STORAGE_CONSTANTS.accessToken);
  const userId = storage.getItem(STORAGE_CONSTANTS.userId);
  if (accessToken && userId) {
    // const decodedAccessToken: IDecodedToken = jwt_decode(accessToken);
    // if (decodedAccessToken.exp >= Math.round(Date.now()/1000)){
    return true;
    // }
  }
  return false;
};

export const isAuthorized = (action: string, data: string[]): boolean => {
  return data?.includes(action);
};
// Creating a promise which opens an connection to listen to the given endpoint
export const listenerApiHelper = (endpoint: string) => {
  return new Promise(function (resolve, reject) {
    const evtSource = new EventSource(endpoint);

    evtSource.addEventListener('message', (event) => {
      if (event.data.toLowerCase().includes(LandingZoneStatus.CREATED.toLowerCase())) {
        evtSource.close();
        resolve(event.data);
      } else if (event.data.toLowerCase().includes(LandingZoneStatus.ERROR.toLowerCase())) {
        evtSource.close();
        resolve(event.data);
      } else {
        reject();
      }
    });
  });
};

// Method to upload File onto S3
export const uploadFile = (url: string, body: IUploadFileBody): Promise<Response> => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return fetch(url, {
    method: 'POST',
    body: formData,
  });
};

export const getNextFiveYears = (year: string): string[] => {
  return [0, 1, 2, 3, 4, 5].map((add) => (parseInt(year) + add).toString());
};

export const calculatePercentageFromScore = (score: number): number => (score * 100) / 4;

export const getOpsModelColorScale = (percent: number): string => {
  if (percent < 50) {
    const colors = AssessmentDetailsColor[0] as string[];
    const colorScale = new ColorScale(0, 50, colors);
    return colorScale.getColor(percent).toHexString();
  } else if (percent < 75) {
    const colors = AssessmentDetailsColor[1] as string[];
    const colorScale = new ColorScale(50, 75, colors);
    return colorScale.getColor(percent).toHexString();
  } else {
    const colors = AssessmentDetailsColor[2] as string[];
    const colorScale = new ColorScale(75, 100, colors);
    return colorScale.getColor(percent).toHexString();
  }
};

//Method to check wether the string is valid JSON
export const jsonStringValidator = (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};
export const timeSince = (time: Date) => {
  let date = new Date().setDate(time.getDate());
  date = time.getTime();
  const time_formats = [
    [60, 'seconds', 1], // 60
    [3600, 'minute(s)', 60], // 60*60, 60
    [86400, 'hour(s)', 3600], // 60*60*24, 60*60
    [604800, 'day(s)', 86400], // 60*60*24*7, 60*60*24
    [2419200, 'week(s)', 604800], // 60*60*24*7*4, 60*60*24*7
    [29030400, 'month(s)', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [2903040000, 'year(s)', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
  ];
  const seconds = (+new Date() - date) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds === 0) {
    return 'Just now';
  }

  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < Number(format[0])) {
      if (typeof format[2] === 'string') {
        return format[list_choice];
      } else
        return (
          (Math.floor(seconds / format[2]) as unknown as string) +
          ' ' +
          (format[1] as unknown as string) +
          ' ' +
          token
        );
    }
  return time;
};

//Method to Get TimeStamp of day from current timestamp
export const getTimeDayStamp = (days: number) => moment().subtract(days, 'days').format();

//Method to open url in new tab
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

//helper function to handle the viewport on zoom change
export const handleZoom = (className: string) => {
  const selectedZoom =
    storage.getItem(STORAGE_CONSTANTS.zoomValue) ??
    document.body?.className?.split(' ')?.filter((cl) => cl.includes('zoom'))?.[0] ??
    'zoom-plus';
  const existingZoomClasses = document
    .getElementsByClassName(className)?.[0]
    ?.className?.split(' ')
    ?.filter((cl) => cl.includes('vh-zoom'));
  existingZoomClasses?.map((zoomClass) => {
    document.getElementsByClassName(className)?.[0]?.classList?.remove(zoomClass);
  });
  document.getElementsByClassName(className)?.[0]?.classList.add(`vh-${selectedZoom}`);
};

//function to remove the / atg the end of url if it exist
export const formatUrl = (url: string) => {
  if (url?.charAt(url.length - 1) == '/') {
    url = url?.slice(0, -1);
  }
  return url;
};

//CBO-3580_AI_AUTO_ENHANCE_CODE : export to excel functionality made resusable
export const generateAndDownloadExcel = async (
  jsonData: any[],
  columns: any[],
  filename: string,
) => {
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook?.addWorksheet(filename);

  // Define columns
  worksheet.columns = columns;

  // Add rows from JSON data
  jsonData?.forEach((data) => {
    worksheet?.addRow(data);
  });

  // Apply styles to header
  const headerRow = worksheet?.getRow(1);
  headerRow.font = { bold: true, size: 12 };

  // Use exceljs's built-in functionality to write the workbook to a buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Use file-saver to save the file client-side
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${filename}.xlsx`);
};

/**
 * Generates an Excel report based on the provided configuration and triggers a download of the file.
 * CBO-4367_AI_AUTO_NEW_CODE
 *
 * @param {IReportConfig} reportConfig - The configuration object for the report, including worksheets, columns, and data.
 *
 * @returns {Promise<void>} A promise that resolves when the Excel file has been generated and downloaded.
 *
 * @example
 * const reportConfig = {
 *   filename: 'Report',
 *   worksheets: [
 *     {
 *       sheetName: 'Sheet1',
 *       columns: [
 *         { header: 'Column1', key: 'col1', width: 10 },
 *         { header: 'Column2', key: 'col2', width: 20 },
 *       ],
 *       data: [
 *         { col1: 'Data1', col2: 'Data2' },
 *         { col1: 'Data3', col2: 'Data4' },
 *       ],
 *     },
 *   ],
 * };
 *
 * generateAndDownloadExcelReport(reportConfig);
 */
export const generateAndDownloadExcelReport = async (reportConfig: IReportConfig) => {
  const workbook = new ExcelJS.Workbook();

  reportConfig?.worksheets?.forEach((sheet) => {
    const worksheet = workbook?.addWorksheet(sheet?.sheetName);

    // Define columns
    worksheet.columns = sheet?.columns;

    // Add rows from JSON data
    sheet?.data?.forEach((data) => {
      worksheet?.addRow(data);
    });

    // Apply styles to header
    const headerRow = worksheet?.getRow(1);
    headerRow.font = { bold: true, size: 11 };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',

        pattern: 'solid',
        fgColor: { argb: 'EDEDED' },
      };
    });
    // Apply border to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  });

  // Use exceljs's built-in functionality to write the workbook to a buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Use file-saver to save the file client-side
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${reportConfig?.filename}.xlsx`);
};

// To remove the cookie
export const removeCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

/**
 * Rounds a number to a specified number of decimal places.
 * @param num - The number to round.
 * @param decimalPlaces - The number of decimal places to display.
 * @returns The rounded number.
 */
export const roundToDecimalPlaces = (num: number, decimalPlaces: number): number => {
  if (isNaN(num)) return 0;
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

/**
 * Converts an image ArrayBuffer to a base64 string.
 * @param data - The image ArrayBuffer.
 * @returns A promise that resolves with the base64 string.
 * CBO-3939_AI_AUTO_NEW_CODE: Added this function to convert image to base64
 */
export const convertImageToBase64 = (data: ArrayBuffer) => {
  const blob = new Blob([data], { type: 'image/jpeg' });
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        const base64String = result.split(',')[1];
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert image to base64'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read image file'));
    };
    reader.readAsDataURL(blob);
  });
};

/**
 * Converts a string to camel case.
 * @param str - The string to convert.
 * @returns The camel case version of the string.
 */
export const toCamelCase = (str: string): string => {
  return str?.toLowerCase()?.slice(0, 1)?.toUpperCase() + str?.toLowerCase()?.slice(1);
};

export const toPascalCase = (str: string): string => {
  return str?.toLowerCase()?.slice(0, 1)?.toUpperCase() + str?.toLowerCase()?.slice(1);
};
