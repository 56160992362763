import React from 'react';
import { Button, Drawer, Typography } from 'antd';
import TruNorthDashboard from './TruNorthDashboard/TruNorthDashboard';
import Cb360Dashboard from './Cb360Dashboard/Cb360Dashboard';
import CwbDashboard from './CwbDashboard/CwbDashboard';
import DCADashboard from './DCADashboard/DCADashboard';
import {
  CBOKeyAssetsDisplayName as LABELS,
  TruNorthFeature,
  CBOKeyAssets,
  CBOKeyAssetsDisplayName,
} from '../../containers/CBOSidebar/CBOSidebarConstants';
import './KeyAssets.less';
import TalkQEDashboard from './TalkQEDashboard/TalkQEDashboard';
import CMSDashboard from './CMSDashboard/CMSDashboard';
import OperateEdgeDashboard from './OperateEdgeDashboard/OperateEdgeDashboard';
import InnowakeDiscoveryDashboard from './InnowakeDiscoveryDashboard/InnowakeDiscoveryDashboard';
import ApiVaultDashboard from './ApiVaultDashboard/ApiVaultDashboard';
import TurboCodeDashboard from './TurboCodeDashboard/TurboCodeDashboard';
import DCPDashboard from './DCPDashboard/DCPDashboard';
import DigitalTesterDashboard from './DigitalTesterDashboard/DigitalTesterDashboard';
import { ExpandOutlined } from '@ant-design/icons';
import { IProduct } from '../../libs/models/cbo/EngagementModels';
import { getTurboCodeMSALConfig } from '../CBOLogin/CBOLoginConstants';
import { turboCodeDemoInstanceConfig } from './KeyAssetsConstant';
import CBOTruNorthDecomposeDashboard from './CBOTruNorthDecomposeDashboard/CBOTruNorthDecomposeDashboard';

interface IKeyAssetsProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  url?: string;
  assetName?: string;
  assetUrl?: string;
  selectedAssetConfig?: IProduct;
  isEngagement?: boolean;
  selectedEnagagementName?: string;
  selectedEnagagementId?: string;
}

const KeyAssets = ({
  visible,
  onClose,
  title,
  assetName,
  assetUrl,
  selectedAssetConfig,
  isEngagement,
  selectedEnagagementName,
  selectedEnagagementId,
}: IKeyAssetsProps) => {
  const handleClick = () => {
    window.open(
      isEngagement
        ? selectedAssetConfig?.instanceUrl
        : selectedAssetConfig?.configDone
          ? selectedAssetConfig?.instanceUrl
          : turboCodeDemoInstanceConfig.demoInstanceUrl,
      '_blank',
    );
  };

  return (
    <Drawer
      className="cbo-drawer"
      title={title}
      placement="right"
      width="100%"
      onClose={onClose}
      bodyStyle={{ padding: 0 }}
      visible={visible}
      closeIcon={
        <img
          src={`${process.env.PUBLIC_URL}/config-icons/cbo-config-eng/close-icon.png`}
          alt={'Close Drawer'}
        />
      }
    >
      {assetName == CBOKeyAssets.cboTruNorthModernizationCentral && (
        <TruNorthDashboard
          url={assetUrl}
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboCB360Asset && (
        <Cb360Dashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboCWBAsset && (
        <CwbDashboard
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboDCAAsset && (
        <DCADashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {(title == LABELS.cboTruNorthDecomposeModernization ||
        title === LABELS.cboTruNorthDecomposeDecomposition) && (
        <CBOTruNorthDecomposeDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboTalkQEAsset && (
        <TalkQEDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboCOFTAAsset && <CwbDashboard />}
      {title == LABELS.cms && (
        <CMSDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.operateEdge && (
        <OperateEdgeDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.truNorthCodeDiscovery && (
        <InnowakeDiscoveryDashboard
          selectedAssetConfig={selectedAssetConfig}
          url={assetUrl}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboApiVault && (
        <ApiVaultDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.turboCode && (
        <TurboCodeDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboDCPAsset && (
        <DCADashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
      {title == LABELS.cboDigitalTester && (
        <DigitalTesterDashboard
          selectedAssetConfig={selectedAssetConfig}
          isEngagement={isEngagement}
          selectedEnagagementName={selectedEnagagementName}
          selectedEnagagementId={selectedEnagagementId}
        />
      )}
    </Drawer>
  );
};

export default KeyAssets;
