export interface IAssetData {
  asset_id?: string;
  assetName: string;
  description: string;
  capabilities: ICapability[];
  category: string;
  assetLink: string;
}

export enum SmartStartAction {
  ADD = 'add',
  DELETE = 'delete',
  NOCHANGE = 'nochange',
}
export enum MOFAction {
  ADD = 'new',
  DELETE = 'delete',
  UPDATE = 'modify',
}

export enum SmartStartCategory {
  GPS = 'GPS',
  COMMERCIAL = 'Commercial',
}

export interface ICapability {
  capabilityName: string;
  capabilityId: string;
  action: SmartStartAction;
}

export interface ISearchFilterSmartStartResponse {
  assetData: IAssetData[];
  count: number;
}

export interface IAddSmartStartDataResponse {
  asset_id: string;
}
export interface IAddMOFMappingResponse {
  asset_id: string;
}
export interface ISmartStartMOF {
  asset_id?: string;
  assetName?: string;
  mappingId: string;
  gpsCommercial?: string;
  op?: string;
  offering?: string;
  marketOffering?: string;
  l1code?: string;
  l2code?: string;
  l3code?: string;
  action?: MOFAction;
}

export interface ISmartStartCapability {
  capabilityName: string;
  capabilityId: string;
  asset_id: string;
  assetName: string;
}

export interface IAllSmartStartDataResponse {
  smartStartList: IAssetData[];
  mofList: ISmartStartMOF[];
  capList: ISmartStartCapability[];
}

export interface IReportColumn {
  header: string;
  key: string;
  width?: number;
}

export interface IReportWorksheetConfig {
  sheetName: string;
  columns: IReportColumn[];
  data: any[];
}

export interface IReportConfig {
  filename: string;
  worksheets: IReportWorksheetConfig[];
}
export interface ISmartStartMOFDetailsResponse {
  ssid: string;
  marketOfferingMapping: ISmartStartMOF[];
}

export interface ISmartStartDeleteResponse {
  successMessage: string;
}
