import {
  IAnalyticsUserListResponse,
  IEngagementKeyAssetsData,
  IFiscalYear,
  IOverallSummaryResponseData,
  IPursuitEngagementResponse,
  IPursuitEngReportsPayload,
  IPursuitsEngagementAccumulativePayload,
  IPursuitsEngReportsResponse,
  IUserActivityResponse,
  IUserAnalyticsPayload,
} from '../../models/cbo/cboWebAnalyticsModel';
import {
  IMostVisitedCapabilitiesResponse,
  IVisitedPagePayload,
  ICapabilitiesByPhaseResponse,
  IPursuitVsEngageemntPayload,
} from '../../store/actions/WebAnalytics/TrafficAnalyticsActions';
import { API_ROUTE_CBO } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class TrafficAnalyticsService {
  private readonly CboService: ApiServiceBase = new ApiService(serviceType, ServerType.cbo);
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.ANALYTICS,
    ServerType.tangra,
  );

  public postVisitedMenu(payload: IVisitedPagePayload): Promise<string> | string {
    const route: string[] = [`${API_ROUTE_CBO.POST_VISITED_DATA}`];
    return this.CboService.post({ route: route }, payload);
  }

  public getMostVisitedCapabilitiesPerPhase(
    metricType: string,
  ): Promise<IMostVisitedCapabilitiesResponse[]> | IMostVisitedCapabilitiesResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_METRIC_ANALYTICS}/${metricType}`];
    return this.CboService.get({ route: route });
  }

  public getCapabilitiesByPhase(
    metricType: string,
  ): Promise<ICapabilitiesByPhaseResponse[]> | ICapabilitiesByPhaseResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_METRIC_ANALYTICS}/${metricType}`];
    return this.CboService.get({ route: route });
  }

  public getKeyAssetsCount(
    metricType: string,
  ): Promise<IEngagementKeyAssetsData[]> | IEngagementKeyAssetsData[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_METRIC_ANALYTICS}/${metricType}`];
    return this.CboService.get({ route: route });
  }
  public getPursuitEngegementsData(
    payload: IPursuitVsEngageemntPayload,
  ): Promise<IPursuitEngagementResponse> | IPursuitEngagementResponse {
    const route: string[] = [`${API_ROUTE_CBO.GET_PURSUITS_ENGAGEMENT_METRICS}`];
    return this.CboService.get({
      route: route,
      query: { category: payload.category },
    });
  }
  //CBO-3309:generated services for user activity and user list
  public getAnalyticsUserListService(
    payload: IUserAnalyticsPayload,
  ): Promise<IAnalyticsUserListResponse> | IAnalyticsUserListResponse {
    const route: string[] = [`${API_ROUTE_CBO.GET_ANALYTICS_USER_LIST}`];
    return this.CboService.get({
      route: route,
      query: {
        limit: payload?.limit,
        page: payload?.page,
        text: payload?.text,
        startdate: payload?.startdate,
        enddate: payload?.enddate,
      },
    });
  }
  public getUserActivityService(
    payload: IUserAnalyticsPayload,
  ): Promise<IUserActivityResponse> | IUserActivityResponse {
    const route: string[] = [
      `${API_ROUTE_CBO.GET_USER_ACTIVITY.replace(':userMail', payload?.userMail ?? '')}`,
    ];
    return this.CboService.get({
      route: route,
      query: {
        limit: payload?.limit,
        page: payload?.page,
      },
    });
  }

  public getPursuitAnalyticsService(
    payload: IUserAnalyticsPayload,
  ): Promise<IUserActivityResponse> | IUserActivityResponse {
    const route: string[] = [`${API_ROUTE_CBO.GET_PURSUIT_ANALYTICS}`];
    return this.CboService.get({
      route: route,
      query: {
        limit: payload?.limit,
        page: payload?.page,
        text: payload?.text,
        startdate: payload?.startdate,
        enddate: payload?.enddate,
      },
    });
  }

  public getPursuitEngOverallSummaryService(
    payload: string,
  ): Promise<IOverallSummaryResponseData[]> | IOverallSummaryResponseData[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_OVERALL_SUMMARY_STATUS_REPORT}`];
    return this.CboService.get({ route: route, query: { type: payload } });
  }
  public getPursuitAccumulativeDataService(
    payload: IPursuitsEngagementAccumulativePayload,
  ): Promise<IOverallSummaryResponseData[]> | IOverallSummaryResponseData[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_PURSUITS_ACCUMULATIVE_REPORT}`];
    return this.CboService.get({
      route: route,
      query: { reportType: payload?.reportType, fiscalYear: payload.fiscalYear },
    });
  }
  public getEngagementAccumulativeDataService(
    payload: IPursuitsEngagementAccumulativePayload,
  ): Promise<IOverallSummaryResponseData[]> | IOverallSummaryResponseData[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_ENGAGEMENTS_ACCUMULATIVE_REPORT}`];
    return this.CboService.get({
      route: route,
      query: { reportType: payload?.reportType, fiscalYear: payload.fiscalYear },
    });
  }

  public getFiscalYearMetaDataService(): Promise<IFiscalYear[]> | IFiscalYear[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_FISCAL_YEARS_META_DATA}`];
    return this.CboService.get({ route: route });
  }

  public getPursuitEngReportsTableDataService(
    payload: IPursuitEngReportsPayload,
  ): Promise<IPursuitsEngReportsResponse> | IPursuitsEngReportsResponse {
    const route: string[] = [
      `${payload.isEngagement ? API_ROUTE_CBO.GET_ENGAGEMENT_REPORTS_TABLE : API_ROUTE_CBO.GET_PURSUIT_REPORTS_TABLE}`,
    ];
    delete payload?.isEngagement;
    return this.CboService.post({ route: route }, payload);
  }
}

const trafficAnalyticsService = new TrafficAnalyticsService();
export default trafficAnalyticsService;
