import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';
import AnalyticsState from '../state/AnalyticsState';
import BuildDeployState from '../state/BuildDeployState';
import CloudSuitabilityState from '../state/CloudSuitabilityState';
import HitAnalytics from '../state/HitAnalyticsState';
import LoginState from '../state/LoginState';
import SidebarState from '../state/SidebarState';
import ToastState from '../state/ToastState';
import TrafficAnalyticsState from '../state/TrafficAnalyticsState';
import UserState from '../state/UserState';
import CboViewSummaryState from '../state/cbo/CboViewSummaryState';
import CboMarketplaceState from '../state/cbo/CboMarketplaceState';
import EngagementState from '../state/cbo/EngagementState';
import IEngagementSummaryState from '../state/cbo/EngagementSummaryState';
import ClientEngagementManagementState from '../state/cbo/ClientEngagementManagementState';
import CboUserManagementState from '../state/cbo/UserManagementState';
import ICboMarketplaceState from '../state/cbo/CboMarketplaceState';
import RBACState from '../state/cbo/RBACState';
import IRefreshTokenState from '../state/cbo/RefreshTokenState';
import IJourneyBuilderState from '../state/cbo/JourneyBuilderState';
import { loginReducer } from './LoginReducer';
import { sidebarReducer } from './SidebarReducer';
import { buildDeployReducer } from './BuildDeployReducer';
import { AnalyticsReducer } from './AnalyticsReducer';
import { CloudSuitabilityReducer } from './CloudSuitabilityReducer';
import { toastReducer } from './ToastReducer';
import { userReducer } from './UserReducer';
import { HitsAnalyticsReducer } from './WebAnalyticsReducer/HitAnalyticsReducer';
import { TrafficAnalyticsReducer } from './WebAnalyticsReducer/TrafficAnalyticsReducer';
import { cboViewSummaryReducer } from './cbo/CboViewSummaryReducer';
import { EngagementReducer } from './cbo/EngagementReducer';
import { engagementSummaryReducer } from './cbo/EngagementSummaryReducer';
import { ClientEngagementManagementReducer } from './cbo/ClientEngagementManagementReducer';
import { cboMarketplaceReducer } from './cbo/CboMarketplaceReducer';
import { CboUserManagementReducer } from './cbo/UserManagementReducer';
import { RBACReducer } from './cbo/RBACReducer';
import { RefreshTokenReducer } from './cbo/RefreshTokenReducer';
import { JourneyBuilderReducer } from './cbo/JourneyBuilderReducer';
import { CapabilityManagementReducer } from './cbo/CapabilityManagementReducer';
import CapabilityManagementState from '../state/cbo/CapabilityManagementState';
import IEngagementSummaryGraphState from '../state/cbo/EngagementSummaryGraphState';
import { engagementSummaryGraphReducer } from './cbo/EngagementSummaryGraphReducer';
import ICboMarketplaceSearchState from '../state/cbo/CboMarketplaceSearchState';
import { cboMarketplaceSearchReducer } from './cbo/CboMarketplaceSearchReducer';
import { smartStartReducer } from './cbo/SmartStartReducer';
import ISmartStartState from '../state/cbo/SmartStartState';
/// <summary>
/// Member names in ApplicationState must match member names in rootReducers
/// </summary>

export interface IApplicationState {
  login: LoginState;
  user: UserState;
  sidebar: SidebarState;
  buildDeploy: BuildDeployState;
  cboViewSummary: CboViewSummaryState;
  cboMarketplace: CboMarketplaceState;
  analytics: AnalyticsState;
  cloudSuitability: CloudSuitabilityState;
  toast: ToastState;
  trafficAnalytics: TrafficAnalyticsState;
  hitAnalytics: HitAnalytics;
  engagement: EngagementState;
  engagementSummary: IEngagementSummaryState;
  clientEngagementManagement: ClientEngagementManagementState;
  cboUserManagement: CboUserManagementState;
  rbac: RBACState;
  refreshData: IRefreshTokenState;
  marketplaceAssets: ICboMarketplaceState;
  journeyBuilder: IJourneyBuilderState;
  capabilityManagement: CapabilityManagementState;
  engagementSummaryGraph: IEngagementSummaryGraphState;
  marketplaceSearch: ICboMarketplaceSearchState;
  smartStart: ISmartStartState;
}

const appReducer = combineReducers<IApplicationState>({
  login: loginReducer,
  user: userReducer,
  sidebar: sidebarReducer,
  buildDeploy: buildDeployReducer,
  cboViewSummary: cboViewSummaryReducer,
  cboMarketplace: cboMarketplaceReducer,
  analytics: AnalyticsReducer,
  cloudSuitability: CloudSuitabilityReducer,
  toast: toastReducer,
  trafficAnalytics: TrafficAnalyticsReducer,
  hitAnalytics: HitsAnalyticsReducer,
  engagement: EngagementReducer,
  engagementSummary: engagementSummaryReducer,
  clientEngagementManagement: ClientEngagementManagementReducer,
  cboUserManagement: CboUserManagementReducer,
  rbac: RBACReducer,
  refreshData: RefreshTokenReducer,
  marketplaceAssets: cboMarketplaceReducer,
  journeyBuilder: JourneyBuilderReducer,
  capabilityManagement: CapabilityManagementReducer,
  engagementSummaryGraph: engagementSummaryGraphReducer,
  marketplaceSearch: cboMarketplaceSearchReducer,
  smartStart: smartStartReducer,
});
const rootReducer: (
  state: IApplicationState | undefined,
  action: AnyAction,
) => IApplicationState = (state, action) => {
  // Emptying redux state on LOGOUT_ACTION
  if (action.type === 'TOGGLE_LOGOUT') {
    if (!action.data) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
  }
  return appReducer(state, action);
};

export const useAppSelector: TypedUseSelectorHook<IApplicationState> = useSelector;

export default rootReducer;
