/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Logging } from '../helper/Logging';
import ApiServiceBase from './ApiServiceBase';
import { HttpContentType } from './HttpContentType';
import { IMultipartFormData } from './MultipartFormData.data';
import AxiosInstance from './AxiosInstance';
import { QueryPath } from './QueryPath.data';
//import { ServiceType, ServerType } from './ServiceType.data';
import { ServerType, ServiceType, IDecodedToken, IApiAdditionalData } from './ServiceType.data';
import { storage, STORAGE_CONSTANTS } from './LocalStorage';
import jwt_decode from 'jwt-decode';
import { AppSettings } from '../../AppSettings';
import { API_ROUTE_CBO } from './ApiRoute';
import { routes } from '../../navigation/Routes';
import { CBOKeyAssets } from '../../containers/CBOSidebar/CBOSidebarConstants';
import { formatUrl, getEnv } from '../helper/HelperFunctions';
import { congnitoConfiguration } from '../../configs/AWS';
import { digitalTesterDemoInstanceConfig } from '../../containers/KeyAssets/KeyAssetsConstant';
import { AxiosResponse } from 'axios';
/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default class ApiService extends ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly serverType: ServerType;

  public constructor(serviceType: ServiceType, serverType: ServerType = ServerType.none) {
    super(serviceType, serverType);
    this.serviceType = serviceType;
    this.serverType = serverType;
    storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
  }

  public checkRefreshToken<T = void>(path: QueryPath): Promise<T> {
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';

    if (
      decodedAccessToken &&
      decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
      storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
    ) {
      const instance = AxiosInstance.create();
      storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');
      return new Promise<T>((resolve, reject) =>
        instance
          .get(
            `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
            {
              headers: {
                access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
              },
            },
          )
          .then((response) => {
            const { access_token, id_token } = response.data;
            storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
            storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
            const IdToken: any = jwt_decode(id_token);
            storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
            storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
            resolve(this.get(path));
          })
          .catch(function (error) {
            storage.clearAll();
            reject(error);
            window.location.href = routes.cbologin;
          }),
      );
    } else {
      return this.get(path);
    }
  }

  public getKeycloakToken<T = AxiosResponse>(
    baseUrl: string,
    user: string,
    cred: string,
    clientId: string,
    clientSecret: string = '',
    grantType: string = 'password',
  ): Promise<T> {
    const instance = AxiosInstance.create();
    const body = clientSecret
      ? `grant_type=${grantType}&client_id=${clientId}&client_secret=${clientSecret}&username=${user}&password=${cred}`
      : `grant_type=${grantType}&client_id=${clientId}&username=${user}&password=${cred}`;
    return instance.post(`${baseUrl}/${API_ROUTE_CBO.KEYCLOAK_TOKEN}`, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public getCallPRomise<T = AxiosResponse>(
    path: QueryPath,
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    const instance = AxiosInstance.create();
    return new Promise<T>((resolve, reject) =>
      instance.get(this.getUrl(path, additionalData), this.getConfig(HttpContentType.Json)).then(
        (res: any) => {
          Logging.trace(`ApiService.get ${this.getUrl(path, additionalData)} @Result`, {
            path,
            result: res,
          });
          resolve(res.data);
        },
        (err: any) => {
          Logging.error(`ApiService.get ${this.getUrl(path, additionalData)} @Error`, {
            path,
            error: err,
          });
          reject(this.processError(err));
        },
      ),
    );
  }

  public get<T = void>(path: QueryPath, additionalData?: IApiAdditionalData): Promise<T> {
    Logging.debug(`ApiService.get ${this.getUrl(path, additionalData)}`, { path });
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';

    if (decodedAccessToken) {
      if (
        decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
        storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
      ) {
        const instance = AxiosInstance.create({ withCredentials: false });
        storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');

        return new Promise<T>((resolve, reject) =>
          instance
            .get(
              `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
              {
                headers: {
                  access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                  userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                  user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                },
              },
            )
            .then((response) => {
              const { access_token, id_token } = response.data;
              storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
              storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
              const IdToken: any = jwt_decode(id_token);
              storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
              storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
              resolve(
                new Promise<T>((resolve, reject) =>
                  instance
                    .get(this.getUrl(path, additionalData), this.getConfig(HttpContentType.Json))
                    .then(
                      (res: any) => {
                        Logging.trace(
                          `ApiService.get ${this.getUrl(path, additionalData)} @Result`,
                          {
                            path,
                            result: res,
                          },
                        );

                        //  Workaround for token request - needs to be reverted
                        resolve(res.data);
                      },
                      (err: any) => {
                        Logging.error(
                          `ApiService.get ${this.getUrl(path, additionalData)} @Error`,
                          {
                            path,
                            error: err,
                          },
                        );
                        reject(this.processError(err));
                      },
                    ),
                ),
              );
            })
            .catch(function (error) {
              storage.clearAll();
              reject(new Error('Your session is expired. Please re-login'));
            }),
        );
      } else if (storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'true') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.get(path));
          }, 1500);
        });
      } else {
        const instance = AxiosInstance.create();
        return new Promise<T>((resolve, reject) =>
          instance
            .get(this.getUrl(path, additionalData), this.getConfig(HttpContentType.Json))
            .then(
              (res: any) => {
                Logging.trace(`ApiService.get ${this.getUrl(path, additionalData)} @Result`, {
                  path,
                  result: res,
                });

                //  Workaround for token request - needs to be reverted
                resolve(res.data);
              },
              (err: any) => {
                Logging.error(`ApiService.get ${this.getUrl(path, additionalData)} @Error`, {
                  path,
                  error: err,
                });
                reject(this.processError(err));
              },
            ),
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  }

  public post<T = void>(
    path: QueryPath,
    body: any,
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.post ${this.getUrl(path, additionalData)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';

    if (decodedAccessToken) {
      if (
        decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
        storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
      ) {
        const instance = AxiosInstance.create();
        storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');
        return new Promise<T>((resolve, reject) =>
          instance
            .get(
              `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
              {
                headers: {
                  access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                  userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                  user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                },
              },
            )
            .then((response) => {
              const { access_token, id_token } = response.data;
              storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
              storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
              const IdToken: any = jwt_decode(id_token);
              storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
              storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
              resolve(
                new Promise<T>((resolve, reject) =>
                  instance
                    .post(
                      this.getUrl(path, additionalData),
                      body,
                      this.getConfig(HttpContentType.Json),
                    )
                    .then(
                      (res: any) => {
                        Logging.trace(
                          `ApiService.post ${this.getUrl(path, additionalData)} @Result`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            result: res,
                          },
                        );
                        resolve(res.data.data ? res.data.data : res.data);
                      },
                      (err: any) => {
                        Logging.error(
                          `ApiService.post ${this.getUrl(path, additionalData)} @Error`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            error: err,
                          },
                        );
                        reject(this.processError(err));
                      },
                    ),
                ),
              );
            })
            .catch(function (error) {
              storage.clearAll();
              reject(new Error('Your session is expired. Please re-login'));
            }),
        );
      } else if (storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'true') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.post(path, body));
          }, 1500);
        });
      } else {
        const instance = AxiosInstance.create();
        return new Promise<T>((resolve, reject) =>
          instance
            .post(this.getUrl(path, additionalData), body, this.getConfig(HttpContentType.Json))
            .then(
              (res: any) => {
                Logging.trace(`ApiService.post ${this.getUrl(path, additionalData)} @Result`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  result: res,
                });
                resolve(res.data.data ? res.data.data : res.data);
              },
              (err: any) => {
                Logging.error(`ApiService.post ${this.getUrl(path, additionalData)} @Error`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  error: err,
                });
                reject(this.processError(err));
              },
            ),
        );
      }
    } else if (
      path.route?.includes(API_ROUTE_CBO.CBO_LOGIN) ||
      path.route?.includes(API_ROUTE_CBO.POST_CUSTOM_LOGIN) ||
      path.route?.includes(API_ROUTE_CBO.POST_CHANGE_PASSWORD)
    ) {
      const instance = AxiosInstance.create();
      return new Promise<T>((resolve, reject) =>
        instance
          .post(this.getUrl(path, additionalData), body, this.getConfig(HttpContentType.Json))
          .then(
            (res: any) => {
              Logging.trace(`ApiService.post ${this.getUrl(path, additionalData)} @Result`, {
                path,
                body,
                bodyJson: JSON.stringify(body),
                result: res,
              });
              resolve(res.data.data ? res.data.data : res.data);
            },
            (err: any) => {
              Logging.error(`ApiService.post ${this.getUrl(path, additionalData)} @Error`, {
                path,
                body,
                bodyJson: JSON.stringify(body),
                error: err,
              });
              reject(this.processError(err));
            },
          ),
      );
    } else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  }

  public delete<T = void>(path: QueryPath, additionalData?: IApiAdditionalData): Promise<T> {
    Logging.debug(`ApiService.delete ${this.getUrl(path, additionalData)}`, { path });
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';
    if (decodedAccessToken) {
      if (
        decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
        storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
      ) {
        const instance = AxiosInstance.create();
        storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');
        return new Promise<T>((resolve, reject) =>
          instance
            .get(
              `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
              {
                headers: {
                  access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                  userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                  user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                },
              },
            )
            .then((response) => {
              const { access_token, id_token } = response.data;
              storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
              storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
              const IdToken: any = jwt_decode(id_token);
              storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
              storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
              resolve(
                new Promise<T>((resolve, reject) =>
                  instance
                    .delete(this.getUrl(path, additionalData), this.getConfig(HttpContentType.Json))
                    .then(
                      (res: any) => {
                        Logging.trace(
                          `ApiService.delete ${this.getUrl(path, additionalData)} @Result`,
                          {
                            path,
                            result: res,
                          },
                        );
                        //  Workaround for token request - needs to be reverted
                        resolve(res.data.data || res.data);
                      },
                      (err: any) => {
                        Logging.error(
                          `ApiService.delete ${this.getUrl(path, additionalData)} @Error`,
                          {
                            path,
                            error: err,
                          },
                        );
                        reject(this.processError(err));
                      },
                    ),
                ),
              );
            })
            .catch(function (error) {
              storage.clearAll();
              reject(new Error('Your session is expired. Please re-login'));
            }),
        );
      } else if (storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'true') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.delete(path));
          }, 1500);
        });
      } else {
        const instance = AxiosInstance.create();
        return new Promise<T>((resolve, reject) =>
          instance
            .delete(this.getUrl(path, additionalData), this.getConfig(HttpContentType.Json))
            .then(
              (res: any) => {
                Logging.trace(`ApiService.delete ${this.getUrl(path, additionalData)} @Result`, {
                  path,
                  result: res,
                });
                resolve(res.data.data || res.data);
              },
              (err: any) => {
                Logging.error(`ApiService.delete ${this.getUrl(path, additionalData)} @Error`, {
                  path,
                  error: err,
                });
                reject(this.processError(err));
              },
            ),
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  }

  public patch<T = void>(
    path: QueryPath,
    body: any,
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.put ${this.getUrl(path, additionalData)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';
    if (decodedAccessToken) {
      if (
        decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
        storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
      ) {
        const instance = AxiosInstance.create();
        storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');
        return new Promise<T>((resolve, reject) =>
          instance
            .get(
              `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
              {
                headers: {
                  access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                  userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                  user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                },
              },
            )
            .then((response) => {
              const { access_token, id_token } = response.data;
              storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
              storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
              const IdToken: any = jwt_decode(id_token);
              storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
              storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
              resolve(
                new Promise<T>((resolve, reject) =>
                  instance
                    .patch(
                      this.getUrl(path, additionalData),
                      body,
                      this.getConfig(HttpContentType.Json),
                    )
                    .then(
                      (res: any) => {
                        Logging.trace(
                          `ApiService.patch ${this.getUrl(path, additionalData)} @Result`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            result: res,
                          },
                        );
                        resolve(res.data.data ? res.data.data : res.data);
                      },
                      (err: any) => {
                        Logging.error(
                          `ApiService.patch ${this.getUrl(path, additionalData)} @Error`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            error: err,
                          },
                        );
                        reject(this.processError(err));
                      },
                    ),
                ),
              );
            })
            .catch(function (error) {
              storage.clearAll();
              reject(new Error('Your session is expired. Please re-login'));
            }),
        );
      } else if (storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'true') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.patch(path, body));
          }, 1500);
        });
      } else {
        const instance = AxiosInstance.create();
        return new Promise<T>((resolve, reject) =>
          instance
            .patch(this.getUrl(path, additionalData), body, this.getConfig(HttpContentType.Json))
            .then(
              (res: any) => {
                Logging.trace(`ApiService.patch ${this.getUrl(path, additionalData)} @Result`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  result: res,
                });
                resolve(res.data.data ? res.data.data : res.data);
              },
              (err: any) => {
                Logging.error(`ApiService.patch ${this.getUrl(path, additionalData)} @Error`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  error: err,
                });
                reject(this.processError(err));
              },
            ),
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  }

  public put<T = void>(
    path: QueryPath,
    body: any,
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.put ${this.getUrl(path, additionalData)}`, {
      path,
      body,
      bodyJson: JSON.stringify(body),
    });
    const token = storage.getItem(STORAGE_CONSTANTS.accessToken) || '';
    const refreshToken = storage.getItem(STORAGE_CONSTANTS.refreshToken) ?? '';
    const decodedAccessToken: IDecodedToken | '' = token ? jwt_decode(token) : '';
    if (decodedAccessToken) {
      if (
        decodedAccessToken.exp < Math.round(Date.now() / 1000) &&
        storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'false'
      ) {
        const instance = AxiosInstance.create();
        storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'true');
        return new Promise<T>((resolve, reject) =>
          instance
            .get(
              `${AppSettings.CBOServer}/${API_ROUTE_CBO.REFRESH_TOKEN}?refresh_token=${refreshToken}`,
              {
                headers: {
                  access_token: storage.getItem(STORAGE_CONSTANTS.accessToken) || '',
                  userid: `${storage.getItem(STORAGE_CONSTANTS.userId)}`,
                  user_type: `${storage.getItem(STORAGE_CONSTANTS.userType)}`,
                },
              },
            )
            .then((response) => {
              const { access_token, id_token } = response.data;
              storage.setItem(STORAGE_CONSTANTS.accessToken, access_token);
              storage.setItem(STORAGE_CONSTANTS.idToken, id_token);
              const IdToken: any = jwt_decode(id_token);
              storage.setItem(STORAGE_CONSTANTS.userGroup, JSON.parse(IdToken['roles']));
              storage.setItem(STORAGE_CONSTANTS.fetchingRefreshToken, 'false');
              resolve(
                new Promise<T>((resolve, reject) =>
                  instance
                    .put(
                      this.getUrl(path, additionalData),
                      body,
                      this.getConfig(HttpContentType.Json),
                    )
                    .then(
                      (res: any) => {
                        Logging.trace(
                          `ApiService.put ${this.getUrl(path, additionalData)} @Result`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            result: res,
                          },
                        );
                        resolve(res.data.data ? res.data.data : res.data);
                      },
                      (err: any) => {
                        Logging.error(
                          `ApiService.put ${this.getUrl(path, additionalData)} @Error`,
                          {
                            path,
                            body,
                            bodyJson: JSON.stringify(body),
                            error: err,
                          },
                        );
                        reject(this.processError(err));
                      },
                    ),
                ),
              );
            })
            .catch(function (error) {
              storage.clearAll();
              reject(new Error('Your session is expired. Please re-login'));
            }),
        );
      } else if (storage.getItem(STORAGE_CONSTANTS.fetchingRefreshToken) == 'true') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.put(path, body));
          }, 1500);
        });
      } else {
        const instance = AxiosInstance.create();
        return new Promise<T>((resolve, reject) =>
          instance
            .put(this.getUrl(path, additionalData), body, this.getConfig(HttpContentType.Json))
            .then(
              (res: any) => {
                Logging.trace(`ApiService.put ${this.getUrl(path, additionalData)} @Result`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  result: res,
                });
                resolve(res.data.data ? res.data.data : res.data);
              },
              (err: any) => {
                Logging.error(`ApiService.put ${this.getUrl(path, additionalData)} @Error`, {
                  path,
                  body,
                  bodyJson: JSON.stringify(body),
                  error: err,
                });
                reject(this.processError(err));
              },
            ),
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  }

  public postMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.postMultipart ${this.getUrl(path, additionalData)}`, {
      path,
      data,
    });
    const instance = AxiosInstance.create();
    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .post(
          this.getUrl(path, additionalData),
          formData,
          this.getConfig(HttpContentType.MultipartFormData),
        )
        .then(
          (res: any) => {
            Logging.trace(`ApiService.postMultipart ${this.getUrl(path, additionalData)} @Result`, {
              path,
              data,
              result: res,
            });
            resolve(res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.postMultipart ${this.getUrl(path, additionalData)} @Error`, {
              path,
              data,
              error: err,
            });
            reject(this.processError(err));
          },
        );
    });
  }

  public putMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.postMultipart ${this.getUrl(path, additionalData)}`, {
      path,
      data,
    });
    const instance = AxiosInstance.create();
    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .put(
          this.getUrl(path, additionalData),
          formData,
          this.getConfig(HttpContentType.MultipartFormData),
        )
        .then(
          (res: any) => {
            Logging.trace(`ApiService.putMultipart ${this.getUrl(path, additionalData)} @Result`, {
              path,
              data,
              result: res,
            });
            resolve(res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.postMultipart ${this.getUrl(path, additionalData)} @Error`, {
              path,
              data,
              error: err,
            });
            reject(this.processError(err));
          },
          // eslint-disable-next-line max-lines
        );
    });
  }

  public patchMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
    additionalData?: IApiAdditionalData,
  ): Promise<T> {
    Logging.debug(`ApiService.patchMultipart ${this.getUrl(path, additionalData)}`, {
      path,
      data,
    });
    const instance = AxiosInstance.create();
    return new Promise<T>((resolve, reject) => {
      const formData = this.prepareMultiPartForm(data);
      return instance
        .patch(
          this.getUrl(path, additionalData),
          formData,
          this.getConfig(HttpContentType.MultipartFormData),
        )
        .then(
          (res: any) => {
            Logging.trace(
              `ApiService.patchMultipart ${this.getUrl(path, additionalData)} @Result`,
              {
                path,
                data,
                result: res,
              },
            );
            resolve(res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.patchMultipart ${this.getUrl(path, additionalData)} @Error`, {
              path,
              data,
              error: err,
            });
            reject(this.processError(err));
          },
        );
    });
  }
}
