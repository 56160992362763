/**
 * SmartStartService.ts
 * CBO-4362_AI_AUTO_NEW_CODE
 *
 * This service handles all operations related to SmartStart data, including fetching, adding, updating, and deleting SmartStart assets and MOF (Market Offering Framework) details.
 *
 * Example usage:
 *
 * import smartStartService from './SmartStartService';
 *
 * async function fetchSmartStartData() {
 *   const payload = { /* your payload here * / };
 *   const response = await smartStartService.getSmartStartData(payload);
 *   console.log(response);
 * }
 */

import {
  IMarketplaceSystemTags,
  ISearchAndFilterAssetsPayload,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import {
  IAddSmartStartDataResponse,
  ISearchFilterSmartStartResponse,
  IAssetData,
  ISmartStartMOF,
  ISmartStartMOFDetailsResponse,
  IAllSmartStartDataResponse,
  ISmartStartDeleteResponse,
} from '../../../models/cbo/SmartStartModel';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class SmartStartService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  // private readonly cboService: ApiServiceBase = new ApiService(
  //   ServiceType.None,
  //   ServerType.localStack,
  // );

  /**
   * Fetches SmartStart data based on the provided search and filter payload.
   * CBO-4362_AI_AUTO_NEW_CODE
   * @param data - The payload containing search and filter criteria.
   * @returns A promise that resolves to the search and filter response.
   */
  public getSmartStartData(
    data: ISearchAndFilterAssetsPayload,
  ): Promise<ISearchFilterSmartStartResponse> | ISearchFilterSmartStartResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.GET_SMARTSTART}`],
      },
      data,
    );
  }

  /**
   * Fetches all SmartStart data.
   * CBO-4367_AI_AUTO_NEW_CODE
   * @returns A promise that resolves to the response containing all SmartStart data.
   */
  public getAllSmartStartData(): Promise<IAllSmartStartDataResponse> | IAllSmartStartDataResponse {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_ALL_SMARTSTART}`],
    });
  }

  /**
   * Fetches SmartStart MOF details for a specific asset.
   * @param payload - The asset ID.
   * @returns A promise that resolves to the response containing MOF details.
   */
  public getSmartStartMOFDetailsListService(
    payload: string,
  ): Promise<ISmartStartMOFDetailsResponse> | ISmartStartMOFDetailsResponse {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_SMARTSTART_MOF_DETAILS.replace(':assetId', payload)}`],
    });
  }

  /**
   * Adds or updates SmartStart data based on the provided payload.
   * @param payload - The asset data.
   * @returns A promise that resolves to the response of the add or update operation.
   */
  public addSmartStartDataService(
    payload: IAssetData,
  ): Promise<IAddSmartStartDataResponse> | IAddSmartStartDataResponse {
    if (payload?.asset_id) {
      return this.cboService.put(
        {
          route: [`${API_ROUTE_CBO.UPDATE_SMART_START}`],
        },
        payload,
      );
    } else {
      return this.cboService.post(
        {
          route: [`${API_ROUTE_CBO.ADD_SMART_START}`],
        },
        payload,
      );
    }
  }

  /**
   * Deletes SmartStart data for a specific asset.
   * CBO-4407_AI_AUTO_NEW_CODE
   * @param payload - The asset ID.
   * @returns A promise that resolves to the response of the delete operation.
   */
  public deleteSmartStartDataService(
    payload: string,
  ): Promise<ISmartStartDeleteResponse> | ISmartStartDeleteResponse {
    return this.cboService.delete({
      route: [`${API_ROUTE_CBO.DELETE_SMART_START.replace(':assetId', payload)}`],
    });
  }

  /**
   * Adds SmartStart MOF data based on the provided payload.
   * @param payload - The MOF data.
   * @returns A promise that resolves to the response of the add operation.
   */
  public addSmartStartMOFService(
    payload: ISmartStartMOF,
  ): Promise<IAddSmartStartDataResponse> | IAddSmartStartDataResponse {
    return this.cboService.patch(
      {
        route: [`${API_ROUTE_CBO.ADD_SMART_START_MOF}`],
      },
      payload,
    );
  }

  /**
   * Fetches the list of SmartStart categories.
   * @returns A promise that resolves to the list of categories.
   */
  public getSmartStartCategoriesService(): Promise<string[]> | string[] {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_SMARTSTART_CATEGORIES}`],
    });
  }

  /**
   * Fetches the list of SmartStart MOF tags.
   * @returns A promise that resolves to the list of MOF tags.
   */
  public getSmartStartMOFTagsService(): Promise<IMarketplaceSystemTags> | IMarketplaceSystemTags {
    return this.cboService.get({
      route: [`${API_ROUTE_CBO.GET_SMARTSTART_TAGS}`],
    });
  }
}

const smartStartService = new SmartStartService();
export default smartStartService;
