/**
 * SmartStartReducer.ts
 *
 * This file contains the reducer logic for handling Smart Start related actions in the Redux store.
 * The reducer updates the state based on the action type and payload received.
 *
 * CBO-4362_AI_AUTO_NEW_CODE
 *
 * Example usage:
 *
 * import { createStore } from 'redux';
 * import { smartStartReducer } from './SmartStartReducer';
 *
 * const store = createStore(smartStartReducer);
 *
 * store.dispatch({ type: 'GET_SMART_START_DATA_START' });
 * console.log(store.getState());
 */

import { Reducer } from 'redux';
import {
  IAllSmartStartDataResponse,
  IAddSmartStartDataResponse,
  IAssetData,
  ISearchFilterSmartStartResponse,
  ISmartStartMOF,
  ISmartStartMOFDetailsResponse,
  ISmartStartDeleteResponse,
} from '../../../models/cbo/SmartStartModel';
import ISmartStartState from '../../state/cbo/SmartStartState';
import { SmartStartAction, SmartStartActionTypes } from '../../actions/cbo/SmartStartActions';
import { IMarketplaceSystemTags } from '../../../models/cbo/CboMarketplaceSearchModels';

/**
 * Initial state for the Smart Start reducer.
 */
export const initialState: ISmartStartState = {
  loading: false,
  searchFilterSmartStartListCompleted: true,
  error: '',
  searchFilterSmartStartList: {} as ISearchFilterSmartStartResponse,
  allSmartStartData: {} as IAllSmartStartDataResponse,
  allSmartStartDataCompleted: true,
  smartStartMOFMappingDetailsList: {} as ISmartStartMOFDetailsResponse,
  smartStartMOFDetailsListCompleted: true,
  smartStartSaveConfigResponse: {} as IAddSmartStartDataResponse,
  selectedSmartStartId: '',
  smartStartCategories: [],
  smartStartCategoriesCompleted: true,
  addSmartStartDataCompleted: true,
  smartStartMOFTags: {} as IMarketplaceSystemTags,
  smartStartMOFTagsCompleted: true,
  smartStartDeleteResponse: {} as ISmartStartDeleteResponse,
};

/**
 * Reducer function for handling Smart Start actions.
 *
 * @param state - The current state of the Smart Start.
 * @param action - The action dispatched.
 * @returns The new state after applying the action.
 */
export const smartStartReducer: Reducer<ISmartStartState> = (
  state = initialState,
  action: SmartStartAction,
) => {
  switch (action.type) {
    // search and filter
    // CBO-4362_AI_AUTO_NEW_CODE
    case SmartStartActionTypes.GET_SMART_START_DATA_START:
      return {
        ...state,
        error: undefined,
        searchFilterSmartStartListCompleted: false,
        searchFilterSmartStartList: {} as ISearchFilterSmartStartResponse,
      };
    case SmartStartActionTypes.GET_SMART_START_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        searchFilterSmartStartListCompleted: true,
        searchFilterSmartStartList: action.data as ISearchFilterSmartStartResponse,
      };
    case SmartStartActionTypes.GET_SMART_START_DATA_ERROR:
      return {
        ...state,
        searchFilterSmartStartListCompleted: true,
        error: action.data as Error,
      };

    // get all smart start data
    // CBO-4367_AI_AUTO_NEW_CODE
    case SmartStartActionTypes.GET_ALL_SMART_START_DATA_START:
      return {
        ...state,
        error: undefined,
        allSmartStartDataCompleted: false,
        allSmartStartData: {} as IAllSmartStartDataResponse,
      };
    case SmartStartActionTypes.GET_ALL_SMART_START_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        allSmartStartDataCompleted: true,
        allSmartStartData: action.data as IAllSmartStartDataResponse,
      };
    case SmartStartActionTypes.GET_ALL_SMART_START_DATA_ERROR:
      return {
        ...state,
        allSmartStartDataCompleted: true,
        error: action.data as Error,
      };

    // get MOF mapping details
    case SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_STARTED:
      return {
        ...state,
        smartStartMOFMappingDetailsList: {} as ISmartStartMOFDetailsResponse,
        smartStartMOFDetailsListCompleted: false,
      };
    case SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_SUCCESS:
      return {
        ...state,
        smartStartMOFMappingDetailsList: action?.data as ISmartStartMOFDetailsResponse,
        smartStartMOFDetailsListCompleted: true,
      };
    case SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_ERROR:
      return {
        ...state,
        smartStartMOFDetailsListCompleted: true,
        error: action?.data as Error,
      };

    // add smart start data
    case SmartStartActionTypes.ADD_SMART_START_DATA_STARTED:
      return {
        ...state,
        smartStartSaveConfigResponse: {} as IAddSmartStartDataResponse,
        addSmartStartDataCompleted: false,
      };
    case SmartStartActionTypes.ADD_SMART_START_DATA_SUCCESS:
      return {
        ...state,
        smartStartSaveConfigResponse: action.data as IAddSmartStartDataResponse,
        addSmartStartDataCompleted: true,
      };
    case SmartStartActionTypes.ADD_SMART_START_DATA_ERROR:
      return {
        ...state,
        error: action?.data as Error,
        addSmartStartDataCompleted: true,
      };

    // delete smart start data
    // CBO-4407_AI_AUTO_NEW_CODE
    case SmartStartActionTypes.DELETE_SMART_START_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        smartStartDeleteResponse: {} as ISmartStartDeleteResponse,
      };
    case SmartStartActionTypes.DELETE_SMART_START_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        smartStartDeleteResponse: action.data as ISmartStartDeleteResponse,
      };
    case SmartStartActionTypes.DELETE_SMART_START_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.data,
      };

    // set selected smart start ID
    case SmartStartActionTypes.SET_SELECTED_SMART_START_ID:
      return {
        ...state,
        selectedSmartStartId: action.data as string,
      };

    // add smart start MOF mapping
    case SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_STARTED:
      return {
        ...state,
        error: undefined,
      };
    case SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_SUCCESS:
      return {
        ...state,
        error: undefined,
      };
    case SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_ERROR:
      return {
        ...state,
        error: action?.data,
      };

    // get smart start categories
    case SmartStartActionTypes.GET_SMART_START_CATEGORIES_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        smartStartCategories: [],
      };
    case SmartStartActionTypes.GET_SMART_START_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        smartStartCategories: action.data as string[],
        error: undefined,
      };
    case SmartStartActionTypes.GET_SMART_START_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.data,
      };

    // get smart start MOF tags
    case SmartStartActionTypes.GET_SMART_START_MOF_TAGS_STARTED:
      return {
        ...state,
        smartStartMOFTagsCompleted: false,
        smartStartMOFTags: {} as IMarketplaceSystemTags,
      };
    case SmartStartActionTypes.GET_SMART_START_MOF_TAGS_SUCCESS:
      return {
        ...state,
        smartStartMOFTagsCompleted: true,
        smartStartMOFTags: action.data as IMarketplaceSystemTags,
      };
    case SmartStartActionTypes.GET_SMART_START_MOF_TAGS_ERROR:
      return {
        ...state,
        smartStartMOFTagsCompleted: true,
        error: action?.data,
      };

    // reset smart start data
    case SmartStartActionTypes.RESET_SMART_START_DATA:
      return {
        ...state,
        loading: false,
        error: '',
        smartStartSaveConfigResponse: {} as IAddSmartStartDataResponse,
        smartStartMOFMappingDetailsList: {} as ISmartStartMOFDetailsResponse,
        selectedMOFMappingId: '',
        selectedSmartStartId: '',
        selectedSmartStartData: {} as IAssetData,
      };

    default:
      return state;
  }
};
