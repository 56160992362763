/**
 * This file contains action creators for the SmartStart feature in the application.
 * These actions are used to interact with the SmartStart-related data in the Redux store.
 * CBO-4362_AI_AUTO_NEW_CODE
 *
 * Example usage:
 *
 * import { SmartStartActions } from './SmartStartActions';
 *
 * dispatch(SmartStartActions.getSmartStartDataStart(payload));
 */

import {
  IMarketplaceSystemTags,
  ISearchAndFilterAssetsPayload,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import {
  IAllSmartStartDataResponse,
  IAddSmartStartDataResponse,
  IAssetData,
  ISearchFilterSmartStartResponse,
  ISmartStartMOF,
  ISmartStartMOFDetailsResponse,
  ISmartStartDeleteResponse,
} from '../../../models/cbo/SmartStartModel';
import { IStoreAction } from '../../StoreHelper';

// Action Types
export enum SmartStartActionTypes {
  GET_SMART_START_DATA_START = 'GET_SMART_START_DATA_START',
  GET_SMART_START_DATA_SUCCESS = 'GET_SMART_START_DATA_SUCCESS',
  GET_SMART_START_DATA_ERROR = 'GET_SMART_START_DATA_ERROR',

  GET_ALL_SMART_START_DATA_START = 'GET_ALL_SMART_START_DATA_START',
  GET_ALL_SMART_START_DATA_SUCCESS = 'GET_ALL_SMART_START_DATA_SUCCESS',
  GET_ALL_SMART_START_DATA_ERROR = 'GET_ALL_SMART_START_DATA_ERROR',
  GET_MOF_MAPPING_DETAILS_STARTED = 'GET_MOF_MAPPING_DETAILS_STARTED',
  GET_MOF_MAPPING_DETAILS_SUCCESS = 'GET_MOF_MAPPING_DETAILS_SUCCESS',
  GET_MOF_MAPPING_DETAILS_ERROR = 'GET_MOF_MAPPING_DETAILS_ERROR',

  ADD_SMART_START_DATA_STARTED = 'ADD_SMART_START_DATA_STARTED',
  ADD_SMART_START_DATA_SUCCESS = 'ADD_SMART_START_DATA_SUCCESS',
  ADD_SMART_START_DATA_ERROR = 'ADD_SMART_START_DATA_ERROR',

  DELETE_SMART_START_DATA_STARTED = 'DELETE_SMART_START_DATA_STARTED',
  DELETE_SMART_START_DATA_SUCCESS = 'DELETE_SMART_START_DATA_SUCCESS',
  DELETE_SMART_START_DATA_ERROR = 'DELETE_SMART_START_DATA_ERROR',

  ADD_SMART_START_MOF_MAPPING_STARTED = 'ADD_SMART_START_MOF_MAPPING_STARTED',
  ADD_SMART_START_MOF_MAPPING_SUCCESS = 'ADD_SMART_START_MOF_MAPPING_SUCCESS',
  ADD_SMART_START_MOF_MAPPING_ERROR = 'ADD_SMART_START_MOF_MAPPING_ERROR',

  GET_SMART_START_CATEGORIES_STARTED = 'GET_SMART_START_CATEGORIES_STARTED',
  GET_SMART_START_CATEGORIES_SUCCESS = 'GET_SMART_START_CATEGORIES_SUCCESS',
  GET_SMART_START_CATEGORIES_ERROR = 'GET_SMART_START_CATEGORIES_ERROR',

  SET_SELECTED_SMART_START_ID = 'SET_SELECTED_SMART_START_ID',

  GET_SMART_START_MOF_TAGS_STARTED = 'GET_SMART_START_MOF_TAGS_STARTED',
  GET_SMART_START_MOF_TAGS_SUCCESS = 'GET_SMART_START_MOF_TAGS_SUCCESS',
  GET_SMART_START_MOF_TAGS_ERROR = 'GET_SMART_START_MOF_TAGS_ERROR',

  RESET_SMART_START_DATA = 'RESET_SMART_START_DATA',
}

export type SmartStartPayload =
  | string
  | Error
  | string[]
  | ISearchAndFilterAssetsPayload
  | ISearchFilterSmartStartResponse
  | IAllSmartStartDataResponse
  | ISmartStartMOFDetailsResponse
  | ISmartStartDeleteResponse
  | IAssetData
  | ISmartStartMOF
  | IMarketplaceSystemTags;

export type SmartStartAction = IStoreAction<SmartStartActionTypes, SmartStartPayload>;

export class SmartStartActions {
  /**
   * Initiates the process to get SmartStart data.
   * CBO-4362_AI_AUTO_NEW_CODE
   * @param data - The payload containing search and filter criteria.
   * @returns The action object.
   */
  public static getSmartStartDataStart(data: ISearchAndFilterAssetsPayload): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_SMART_START_DATA_START,
      data,
    };
  }

  /**
   * Handles the success of getting SmartStart data.
   * CBO-4362_AI_AUTO_NEW_CODE
   * @param data - The response data.
   * @returns The action object.
   */
  public static getSmartStartDataSuccess(data: ISearchFilterSmartStartResponse): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_SMART_START_DATA_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of getting SmartStart data.
   * CBO-4362_AI_AUTO_NEW_CODE
   * @param error - The error object.
   * @returns The action object.
   */
  public static getSmartStartDataError(error: Error): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_SMART_START_DATA_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to get all SmartStart data.
   * CBO-4367_AI_AUTO_NEW_CODE
   * @returns The action object.
   */
  public static getAllSmartStartDataStart(): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_ALL_SMART_START_DATA_START,
    };
  }

  /**
   * Handles the success of getting all SmartStart data.
   * CBO-4367_AI_AUTO_NEW_CODE
   * @param data - The response data.
   * @returns The action object.
   */
  public static getAllSmartStartDataSuccess(data: IAllSmartStartDataResponse): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_ALL_SMART_START_DATA_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of getting all SmartStart data.
   * CBO-4367_AI_AUTO_NEW_CODE
   * @param error - The error object.
   * @returns The action object.
   */
  public static getAllSmartStartDataError(error: Error): SmartStartAction {
    return {
      type: SmartStartActionTypes.GET_ALL_SMART_START_DATA_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to get SmartStart MOF mapping details.
   * @param data - The identifier for the MOF mapping details.
   * @returns The action object.
   */
  public static getSmartStartMOFMappingDetailsListStarted(data: string) {
    return {
      type: SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_STARTED,
      data,
    };
  }

  /**
   * Handles the success of getting SmartStart MOF mapping details.
   * @param data - The response data.
   * @returns The action object.
   */
  public static getSmartStartMOFDetailsListSuccess(data: ISmartStartMOFDetailsResponse) {
    return {
      type: SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of getting SmartStart MOF mapping details.
   * @param error - The error object.
   * @returns The action object.
   */
  public static getSmartStartMOFDetailsListError(error: Error) {
    return {
      type: SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to add SmartStart data.
   * @param data - The asset data to be added.
   * @returns The action object.
   */
  public static addSmartStartDataStarted(data: IAssetData) {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_DATA_STARTED,
      data,
    };
  }

  /**
   * Handles the success of adding SmartStart data.
   * @param data - The response data.
   * @returns The action object.
   */
  public static addSmartStartDataSuccess(data: IAddSmartStartDataResponse) {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_DATA_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of adding SmartStart data.
   * @param error - The error object.
   * @returns The action object.
   */
  public static addSmartStartDataError(error: Error) {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_DATA_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to delete SmartStart data.
   * CBO-4407_AI_AUTO_NEW_CODE
   * @param data - The identifier for the data to be deleted.
   * @returns The action object.
   */
  public static deleteSmartStartDataStarted(data: string) {
    return {
      type: SmartStartActionTypes.DELETE_SMART_START_DATA_STARTED,
      data,
    };
  }

  /**
   * Handles the success of deleting SmartStart data.
   * CBO-4407_AI_AUTO_NEW_CODE
   * @returns The action object.
   */
  public static deleteSmartStartDataSuccess(data: ISmartStartDeleteResponse) {
    return {
      type: SmartStartActionTypes.DELETE_SMART_START_DATA_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of deleting SmartStart data.
   * CBO-4407_AI_AUTO_NEW_CODE
   * @param error - The error object.
   * @returns The action object.
   */
  public static deleteSmartStartDataError(error: Error) {
    return {
      type: SmartStartActionTypes.DELETE_SMART_START_DATA_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to add MOF mapping.
   * @param data - The MOF data to be added.
   * @returns The action object.
   */
  public static addMOFMappingStarted(data: ISmartStartMOF) {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_STARTED,
      data,
    };
  }

  /**
   * Handles the success of adding MOF mapping.
   * @returns The action object.
   */
  public static addMOFMappingSuccess() {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_SUCCESS,
    };
  }

  /**
   * Handles the error of adding MOF mapping.
   * @param error - The error object.
   * @returns The action object.
   */
  public static addMOFMappingError(error: Error) {
    return {
      type: SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to get SmartStart categories.
   * @returns The action object.
   */
  public static getSmartStartCategoriesStarted() {
    return {
      type: SmartStartActionTypes.GET_SMART_START_CATEGORIES_STARTED,
    };
  }

  /**
   * Handles the success of getting SmartStart categories.
   * @param data - The list of categories.
   * @returns The action object.
   */
  public static getSmartStartCategoriesSuccess(data: string[]) {
    return {
      type: SmartStartActionTypes.GET_SMART_START_CATEGORIES_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of getting SmartStart categories.
   * @param error - The error object.
   * @returns The action object.
   */
  public static getSmartStartCategoriesError(error: Error) {
    return {
      type: SmartStartActionTypes.GET_SMART_START_CATEGORIES_ERROR,
      data: error,
    };
  }

  /**
   * Initiates the process to get SmartStart MOF tags.
   * @returns The action object.
   */
  public static getSmartStartMOFTagsStarted() {
    return {
      type: SmartStartActionTypes.GET_SMART_START_MOF_TAGS_STARTED,
    };
  }

  /**
   * Handles the success of getting SmartStart MOF tags.
   * @param data - The MOF tags data.
   * @returns The action object.
   */
  public static getSmartStartMOFTagsSuccess(data: IMarketplaceSystemTags) {
    return {
      type: SmartStartActionTypes.GET_SMART_START_MOF_TAGS_SUCCESS,
      data,
    };
  }

  /**
   * Handles the error of getting SmartStart MOF tags.
   * @param error - The error object.
   * @returns The action object.
   */
  public static getSmartStartMOFTagsError(error: Error) {
    return {
      type: SmartStartActionTypes.GET_SMART_START_MOF_TAGS_ERROR,
      data: error,
    };
  }

  /**
   * Sets the selected SmartStart ID.
   * @param data - The selected SmartStart ID.
   * @returns The action object.
   */
  public static setSelectedSmartStartId(data: String) {
    return {
      type: SmartStartActionTypes.SET_SELECTED_SMART_START_ID,
      data,
    };
  }

  /**
   * Resets the SmartStart data.
   * @returns The action object.
   */
  public static resetSmartStartData() {
    return {
      type: SmartStartActionTypes.RESET_SMART_START_DATA,
    };
  }
}
