/**
 * Purpose: This file contains saga workers and watchers for handling SmartStart related actions.
 * It interacts with the SmartStartService to perform various operations like fetching, adding, and deleting SmartStart data.
 * CBO-4362_AI_AUTO_NEW_CODE
 *
 * Example:
 * To start fetching SmartStart data, dispatch the following action:
 * dispatch(SmartStartActions.getSmartStartDataStart({} as ISearchAndFilterAssetsPayload));
 */

import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  SmartStartAction,
  SmartStartActions,
  SmartStartActionTypes,
} from '../../actions/cbo/SmartStartActions';
import { ToastActions } from '../../actions/ToastActions';
import {
  IAllSmartStartDataResponse,
  IAddSmartStartDataResponse,
  ISearchFilterSmartStartResponse,
  IAssetData,
  ISmartStartMOFDetailsResponse,
  ISmartStartMOF,
  MOFAction,
  ISmartStartDeleteResponse,
} from '../../../models/cbo/SmartStartModel';
import {
  IMarketplaceSystemTags,
  ISearchAndFilterAssetsPayload,
} from '../../../models/cbo/CboMarketplaceSearchModels';
import smartStartService from '../../../services/cbo/SmartStartService/SmartStartService';

/**
 * Worker saga to fetch SmartStart data based on search and filter criteria.
 * CBO-4362_AI_AUTO_NEW_CODE
 * @param action - The action containing the payload for search and filter.
 */
export function* getSmartStartDataWorker(action: SmartStartAction) {
  try {
    const { data } = action;
    const response: ISearchFilterSmartStartResponse = yield smartStartService.getSmartStartData(
      data as ISearchAndFilterAssetsPayload,
    );
    yield put(SmartStartActions.getSmartStartDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.getSmartStartDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to fetch all SmartStart data.
 * CBO-4367_AI_AUTO_NEW_CODE
 */
export function* getAllSmartStartDataWorker() {
  try {
    const response: IAllSmartStartDataResponse = yield smartStartService.getAllSmartStartData();
    yield put(SmartStartActions.getAllSmartStartDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.getAllSmartStartDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to fetch SmartStart MOF details list data.
 * @param action - The action containing the payload for fetching MOF details.
 */
export function* getSmartStartMOFDetailsListDataWorker(action: SmartStartAction) {
  try {
    const { data } = action;
    const response: ISmartStartMOFDetailsResponse =
      yield smartStartService.getSmartStartMOFDetailsListService(data as string);
    yield put(SmartStartActions.getSmartStartMOFDetailsListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.getSmartStartMOFDetailsListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to add SmartStart data.
 * @param action - The action containing the payload for adding SmartStart data.
 */
export function* addSmartStartDataDataWorker(action: SmartStartAction) {
  try {
    const { data } = action;
    const response: IAddSmartStartDataResponse = yield smartStartService.addSmartStartDataService(
      data as IAssetData,
    );
    if (response?.asset_id) {
      yield put(SmartStartActions.setSelectedSmartStartId(response?.asset_id ?? ''));
      yield put(SmartStartActions.addSmartStartDataSuccess(response));
      yield put(
        ToastActions.showToast({
          message: 'Success!!!',
          description: 'SmartStart data saved successfully',
          type: NotificationTypes.SUCCESS,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.addSmartStartDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to add SmartStart MOF mapping.
 * @param action - The action containing the payload for adding MOF mapping.
 */
export function* addSmartStartMOFMappingWorker(action: SmartStartAction) {
  try {
    const { data } = action;
    const mofMappingData = data as ISmartStartMOF;
    const response: IAddSmartStartDataResponse =
      yield smartStartService.addSmartStartMOFService(mofMappingData);

    yield put(SmartStartActions.getSmartStartMOFMappingDetailsListStarted(response?.asset_id));
    yield put(
      ToastActions.showToast({
        message: 'Success!!!',
        description: `MOF mapping ${mofMappingData?.action == MOFAction.ADD ? 'added' : mofMappingData?.action == MOFAction.UPDATE ? 'updated' : 'deleted'} successfully`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.addSmartStartDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to fetch SmartStart categories.
 */
export function* getSmartStartCategoriesWorker() {
  try {
    const response: string[] = yield smartStartService.getSmartStartCategoriesService();
    yield put(SmartStartActions.getSmartStartCategoriesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.getSmartStartCategoriesError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to delete SmartStart data.
 * CBO-4407_AI_AUTO_NEW_CODE
 * @param action - The action containing the payload for deleting SmartStart data.
 */
export function* deleteSmartStartDataWorker(action: SmartStartAction) {
  try {
    const { data } = action;
    const response: ISmartStartDeleteResponse = yield smartStartService.deleteSmartStartDataService(
      data as string,
    );
    if (response?.successMessage) {
      yield put(SmartStartActions.deleteSmartStartDataSuccess(response));
      yield put(
        ToastActions.showToast({
          message: 'Success!!!',
          description: 'SmartStart deleted successfully',
          type: NotificationTypes.SUCCESS,
        }),
      );
    } else {
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: 'Unexpected response format',
          type: NotificationTypes.ERROR,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.deleteSmartStartDataError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Worker saga to fetch SmartStart MOF tags data.
 * @param action - The action containing the payload for fetching MOF tags.
 */
export function* getSmartStartMOFTagsDataWorker(action: SmartStartAction) {
  try {
    const response: IMarketplaceSystemTags = yield smartStartService.getSmartStartMOFTagsService();
    yield put(SmartStartActions.getSmartStartMOFTagsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SmartStartActions.getSmartStartMOFTagsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

/**
 * Watcher saga to watch for SmartStart related actions and trigger corresponding worker sagas.
 * CBO-4362_AI_AUTO_NEW_CODE
 */
export function* smartStartWatcher() {
  yield takeEvery(SmartStartActionTypes.GET_SMART_START_DATA_START, getSmartStartDataWorker);
  yield takeEvery(SmartStartActionTypes.GET_ALL_SMART_START_DATA_START, getAllSmartStartDataWorker);
  yield takeEvery(
    SmartStartActionTypes.GET_MOF_MAPPING_DETAILS_STARTED,
    getSmartStartMOFDetailsListDataWorker,
  );
  yield takeEvery(SmartStartActionTypes.ADD_SMART_START_DATA_STARTED, addSmartStartDataDataWorker);
  yield takeEvery(
    SmartStartActionTypes.ADD_SMART_START_MOF_MAPPING_STARTED,
    addSmartStartMOFMappingWorker,
  );
  yield takeEvery(
    SmartStartActionTypes.GET_SMART_START_CATEGORIES_STARTED,
    getSmartStartCategoriesWorker,
  );
  yield takeEvery(
    SmartStartActionTypes.DELETE_SMART_START_DATA_STARTED,
    deleteSmartStartDataWorker,
  );
  yield takeEvery(
    SmartStartActionTypes.GET_SMART_START_MOF_TAGS_STARTED,
    getSmartStartMOFTagsDataWorker,
  );
}
