/* eslint-disable max-lines */
import { put, takeEvery } from 'redux-saga/effects';
import {
  IJiraInitiativesResponse,
  ILiftAndShiftObject,
  IJiraInitiativesPayload,
  IJiraEpicsPayload,
  IJiraEpicsResponse,
  IJiraTasksPayload,
  IJiraTasksResponse,
  ISummaryMetricsPayload,
  IJiraTaskListResponse,
  IJiraIssueResponse,
  IOperateEndgeIncidentPayload,
  IEnergySavingsResponse,
  ICarbonCreditsResponse,
  ICarbonFootPrintResponse,
  IGreenCloudPayload,
  ILegacyDbData,
  ILegacyGraphData,
  ITurbocodeStoriesFetchPayload,
  IArtifactsGraphPayload,
  IArtifactsLOCResponse,
  IArtifactsResponse,
  IDecompositionResponse,
  IExecutionMetricDetailsResponse,
  ISQLGraphDataResponse,
  IAllSQLGraphPayload,
  ISQLDMLPayload,
  IOtherSQLPayload,
  ISummaryMetricsResp,
  ITurbocodeSprintResponse,
  ITurbocodeStoriesPayload,
  IWorkflowListPayload,
  IWorkflowReportResponse,
  IWorkflowResponse,
  IDEPAlertScanPayload,
  IDEPAlertScanResponse,
  ICodeQuality,
  ICodeQualityPayload,
  IIncidentChartsType,
  IIncidentDetails,
  IIncidentDetailsPayload,
  ICodespacesResponse,
  IProblemInsightsAssignmentGroupData,
  IProblemInsightsPayload,
  IProblemInsightsRunDetailsData,
  IProblemGroupOverviewResponse,
  IKMSResponse,
  IIssueSummaryResponse,
  IRiskSummaryResponse,
  IActiveSummaryResponse,
  ITransitionTableResponseData,
  IJiraStoryListResponse,
  IJiraStoriesPayload,
  IAppsProgressionResponse,
  IServersProgressionResponse,
  IMigrationPayload,
  IAppsProgressionMonthlyResponse,
  IServersProgressionMonthlyResponse,
  IMigrationArcheTypeListResponse,
  ITCOInsightsResponse,
  ICostTrendResponse,
  ICostAllocationSummaryResponse,
  IBlockersAndCausesResponse,
  IAllocationTrendResponse,
  ICloudCostSummaryCostTrendResponse,
  IServersByUpgradeResponse,
  IDispositionsByWaveData,
  IWorkflowPayload,
  IDefectAgeingResponse,
  INumberOfTestCasesResponse,
  ISeverityVsCreatedOnResponse,
  ITableCountResponse,
  ITestRunStatusResponse,
  ITaxonomyEditorData,
} from '../../../models/cbo/EngagementSummaryGraphModel';
import EngagementSummaryGraphService from '../../../services/cbo/EngagementSummaryGraphService/EngagementSummaryGraphService';
import {
  EngagementSummaryGraphActions,
  EngagementSummaryGraphAction,
  EngagementSummaryGraphActionTypes,
} from '../../actions/cbo/EngagementSummaryGraphAction';
import { ToastActions } from '../../actions/ToastActions';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  IApplicationDipositioning,
  IWavePlan,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import {
  IDiscoveryMetrics,
  ISurveyMetrics,
} from '../../../../containers/CBOEngagementSummary/CboTrunorthGraphs/CboTrunorthConstants';
import { IProduct } from '../../../models/cbo/EngagementModels';
import { EngagementActions } from '../../actions/cbo/EngagementAction';
import EngagementService from '../../../services/cbo/EnagementService/EngagementService';
import { IMarketplaceAssetPayload } from '../../../models/cbo/CboMarketplaceModels';
import { STORAGE_CONSTANTS, storage } from '../../../services/LocalStorage';
import {
  ISummaryPractitionerDataResp,
  IDashboardCourseStatusResponse,
  ILearningCenterPayload,
} from '../../../../containers/CBOEngagementSummary/operate/LearningCenter/LearningCenterConstants';

export function* getLiftAndShiftWorker(action: EngagementSummaryGraphAction) {
  try {
    const data = action.data as string;
    const response: ILiftAndShiftObject =
      yield EngagementSummaryGraphService.getLiftAndShiftChart();
    yield put(EngagementSummaryGraphActions.getLiftAndShiftSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getLiftAndShiftError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
export function* getJiraInitiativeDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraInitiativesResponse[] =
      yield EngagementSummaryGraphService.getJiraInitiatives(data as IJiraInitiativesPayload);
    yield put(EngagementSummaryGraphActions.getJiraInititativeSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getJiraInititativeError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJiraEpicsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraEpicsResponse = yield EngagementSummaryGraphService.getJiraEpics(
      data as IJiraEpicsPayload,
    );
    yield put(EngagementSummaryGraphActions.getJiraEpicsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getJiraEpicsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getCurrentSprintDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraIssueResponse =
      yield EngagementSummaryGraphService.getJiraCurrentSprintStoryList(
        data as IJiraStoriesPayload | IJiraTasksPayload,
      );
    response.phase = (data as IJiraTasksPayload)?.phase;
    yield put(EngagementSummaryGraphActions.getCurrentSprintSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      error.name = (action.data as IJiraTasksPayload)?.phase!;
      yield put(EngagementSummaryGraphActions.getCurrentSprintError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJiraTableStoryListDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraStoryListResponse =
      yield EngagementSummaryGraphService.getJiraTableStoryList(data as IJiraStoriesPayload);
    response.phase = (data as IJiraStoriesPayload)?.phase;
    yield put(EngagementSummaryGraphActions.getJiraTableStoryListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getJiraTableStoryListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description:
            'Something went wrong while fetching Activity List! ' +
            error.message +
            '... Please try again!',
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJiraTableTaskListDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraTaskListResponse =
      yield EngagementSummaryGraphService.getJiraTableTaskList(data as IJiraTasksPayload);
    response.phase = (data as IJiraTasksPayload)?.phase;
    yield put(EngagementSummaryGraphActions.getJiraIssueTaskListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      error.name = (action.data as IJiraTasksPayload)?.phase!;
      yield put(EngagementSummaryGraphActions.getJiraIssueTaskListError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description:
            'Something went wrong while fetching Activity List! ' +
            error.message +
            '... Please try again!',
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

// Cloud Migration Fecthing Tasks based on epic Id
// export function* getJiraTaskListDataWorker(action: EngagementSummaryGraphAction) {
//   try {
//     const { data } = action;
//     const response: IJiraTaskListResponse = yield EngagementSummaryGraphService.getJiraTaskList(
//       data as IJiraTasksPayload,
//     );
//     yield put(EngagementSummaryGraphActions.getJiraTaskListSuccess(response));
//   } catch (error) {
//     if (error instanceof Error) {
//       yield put(EngagementSummaryGraphActions.getJiraTaskListError(error));
//       yield put(
//         ToastActions.showToast({
//           message: 'Error!!!',
//           description:
//             'Something went wrong while fetching Activity List! ' +
//             error.message +
//             '... Please try again!',
//           type: NotificationTypes.ERROR,
//         }),
//       );
//     }
//   }
// }

export function* getAllSqlDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISQLGraphDataResponse[] = yield EngagementSummaryGraphService.getSQLGraph(
      data as IAllSQLGraphPayload,
    );
    yield put(EngagementSummaryGraphActions.getSQLGraphDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getSQLGraphDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getDMLSqlDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISQLGraphDataResponse[] = yield EngagementSummaryGraphService.getSQLGraph(
      data as ISQLDMLPayload,
    );
    yield put(EngagementSummaryGraphActions.getDMLSQLGraphDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDMLSQLGraphDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getOtherSqlDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISQLGraphDataResponse[] = yield EngagementSummaryGraphService.getSQLGraph(
      data as IOtherSQLPayload,
    );
    yield put(EngagementSummaryGraphActions.getOtherSQLGraphDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getOtherSQLGraphDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getDecompositionDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IDecompositionResponse[] =
      yield EngagementSummaryGraphService.getDecompositionGraph(data as string);
    yield put(EngagementSummaryGraphActions.getDecompositionDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDecompositionDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getMissingArtifactsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IArtifactsResponse[] =
      yield EngagementSummaryGraphService.getMissingArtifactsGraphData(
        data as IArtifactsGraphPayload,
      );
    yield put(EngagementSummaryGraphActions.getMissingArtifactsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getMissingArtifactsDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//low
export function* getCodeQualityLowDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityLow(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityLowDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityLowDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//medium
export function* getCodeQualityMediumDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityMedium(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//high
export function* getCodeQualityHighDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityHighDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityHighDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//very high
export function* getCodeQualityVeryHighDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityVeryHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

// low FCC
export function* getCodeQualityLowDataForFccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityLow(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForFCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForFCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//medium FCC
export function* getCodeQualityMediumDataForFccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityMedium(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataForFCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataForFCCError(error));
    }
  }
}
//high FCC
export function* getCodeQualityHighDataForFccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityHighDataForFCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityHighDataForFCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//very high FCC
export function* getCodeQualityVeryHighDataForFccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityVeryHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataForFCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataForFCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
// low LCC
export function* getCodeQualityLowDataForLccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityLow(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForLCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForLCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//medium LCC
export function* getCodeQualityMediumDataForLccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityMedium(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataForLCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityMediumDataForLCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}
//high LCC
export function* getCodeQualityHighDataForLccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityHighDataForLCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityHighDataForLCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//very high LCC
export function* getCodeQualityVeryHighDataForLccWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityVeryHigh(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataForLCCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityVeryHighDataForLCCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

// low SC
export function* getCodeQualityLowDataForScWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICodeQuality[] = yield EngagementSummaryGraphService.getCodeQualityLow(
      data as ICodeQualityPayload,
    );
    yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForSCSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCodeQualityLowDataForSCError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getAnalysedArtifactsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IArtifactsResponse[] =
      yield EngagementSummaryGraphService.getAnalyzedArtifactsGraphData(
        data as IArtifactsGraphPayload,
      );
    yield put(EngagementSummaryGraphActions.getAnalysedArtifactsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getAnalysedArtifactsDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getLOCArtifactsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IArtifactsLOCResponse[] = yield EngagementSummaryGraphService.getLOCGraphData(
      data as IArtifactsGraphPayload,
    );
    yield put(EngagementSummaryGraphActions.getArtifactsLOCDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getArtifactsLOCDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//SUMMARY METRICS
//module
export function* getMetricsModuleDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getMetricsModuleDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getMetricsModuleDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Physical Files
export function* getPhysicalFilesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getPhysicalFilesDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getPhysicalFilesDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Physical LOC
export function* getPhysicalLOCDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getPhysicalLOCDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getPhysicalLOCDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Source LOC
export function* getSourceLOCDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getSourceLOCDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getSourceLOCDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Comment LOC
export function* getCommentLOCDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getCommentLOCDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCommentLOCDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//dead Code
export function* getDeadCodeDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getDeadCodeDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDeadCodeDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Missing Modules
export function* getMissingModulesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.getMissingModulesDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getMissingModulesDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

//Errors Data
export function* getErrorsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryMetricsResp[] = yield EngagementSummaryGraphService.getMetricsModule(
      data as ISummaryMetricsPayload,
    );
    yield put(EngagementSummaryGraphActions.geErrorsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.geErrorsDataError(error));
      //yield put(
      //     ToastActions.showToast({
      //    message: 'Error!!!',
      // description: error.message,
      //type: NotificationTypes.ERROR,
      //}),
      // );
    }
  }
}

export function* getJiraTableInitiativeDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraIssueResponse =
      yield EngagementSummaryGraphService.getJiraTableInitiatives(data as IJiraInitiativesPayload);
    response.phase = (data as IJiraTasksPayload)?.phase;
    yield put(EngagementSummaryGraphActions.getJiraTableInititativeSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      error.name = (action.data as IJiraInitiativesPayload)?.phase!;
      yield put(EngagementSummaryGraphActions.getJiraTableInititativeError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getJiraTableEpicsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IJiraIssueResponse = yield EngagementSummaryGraphService.getJiraTableEpics(
      data as IJiraEpicsPayload,
    );
    response.phase = (data as IJiraTasksPayload)?.phase;
    yield put(EngagementSummaryGraphActions.getJiraTableEpicsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      error.name = (action.data as IJiraTasksPayload)?.phase!;
      yield put(EngagementSummaryGraphActions.getJiraTableEpicsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getApplicationDepositionDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IApplicationDipositioning =
      yield EngagementSummaryGraphService.getApplicationDispositioning(data as string);
    yield put(EngagementSummaryGraphActions.getApplicationDispositioningSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getApplicationDispositioningError(error));
    }
  }
}

export function* getWavePlanDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IWavePlan = yield EngagementSummaryGraphService.getWavePlan(data as string);
    yield put(EngagementSummaryGraphActions.getWavePlanSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getWavePlanError(error));
    }
  }
}

export function* getInventorySurveyMetricsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISurveyMetrics = yield EngagementSummaryGraphService.getInventorySurveyMetrics(
      data as string,
    );
    yield put(EngagementSummaryGraphActions.getInventortSurveyDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getInventortSurveyDataError(error));
    }
  }
}

export function* getBusinessSurveyMetricsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISurveyMetrics = yield EngagementSummaryGraphService.getBusinessSurveyMetrics(
      data as string,
    );
    yield put(EngagementSummaryGraphActions.getBusinessSurveyDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getBusinessSurveyDataError(error));
    }
  }
}
//discovery metrics
export function* getDiscoveryMetricsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IDiscoveryMetrics = yield EngagementSummaryGraphService.getDiscoveryMetrics(
      data as string,
    );
    yield put(EngagementSummaryGraphActions.getDiscoveryMetricsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDiscoveryMetricsDataError(error));
    }
  }
}

export function* postIncidentSummaryAndTrendsWorker(action: EngagementSummaryGraphAction) {
  try {
    const body = action.data as IOperateEndgeIncidentPayload;
    const response: IIncidentChartsType =
      yield EngagementSummaryGraphService.postIncidentSummaryAndTrend(body)!;
    yield put(EngagementSummaryGraphActions.postIncidentSummaryAndTrendDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.postIncidentSummaryAndTrendDataError(error));
    }
  }
}

export function* postIncidentDetailsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const response: IIncidentDetails = yield EngagementSummaryGraphService.postIncidentDetails(
      action.data as IIncidentDetailsPayload,
    )!;
    yield put(EngagementSummaryGraphActions.postIncidentDetailsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.postIncidentDetailsDataError(error));
    }
  }
}

export function* postKeyAssetConfigDataWorker(action: EngagementSummaryGraphAction) {
  const isEngagement = JSON.parse(
    storage.getItem(STORAGE_CONSTANTS.isEngagement) ?? 'false',
  ) as boolean;

  try {
    const body = action.data as IProduct;
    yield EngagementSummaryGraphService.setKeyAssetConfiguration(body)!;
    yield put(EngagementSummaryGraphActions.setKeyAssetConfigSuccess());
    yield put(
      ToastActions.showToast({
        message: '',
        description: 'Updated Key Asset Configuration!',
        type: NotificationTypes.SUCCESS,
      }),
    );
    try {
      yield put(
        EngagementActions.getKeyAssetsListStart({
          selectedEnagagementId: isEngagement ? body?.engagementId : body?.pursuitId,
          isEngagement: isEngagement,
        } as IMarketplaceAssetPayload),
      );
    } catch (error) {
      if (error instanceof Error) {
        yield put(EngagementActions.getKeyAssetsListError(error));
        yield put(
          ToastActions.showToast({
            message: 'Error!!!',
            description: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.setKeyAssetConfigError(error));
    }
  }
}

export function* getCarbonFootPrintDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICarbonFootPrintResponse =
      yield EngagementSummaryGraphService.getGreenCloudData(data as IGreenCloudPayload);
    yield put(EngagementSummaryGraphActions.getCarbonFootPrintDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCarbonFootPrintDataError(error));
    }
  }
}

export function* getCarbonCreditsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ICarbonCreditsResponse = yield EngagementSummaryGraphService.getGreenCloudData(
      data as IGreenCloudPayload,
    );
    yield put(EngagementSummaryGraphActions.getCarbonCreditsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCarbonCreditsDataError(error));
    }
  }
}

export function* getEnergySavingsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IEnergySavingsResponse = yield EngagementSummaryGraphService.getGreenCloudData(
      data as IGreenCloudPayload,
    );
    yield put(EngagementSummaryGraphActions.getEnergySavingsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getEnergySavingsDataError(error));
    }
  }
}

export function* getTurbocodeStoriesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ITurbocodeSprintResponse =
      yield EngagementSummaryGraphService.getTurbocodeStoriesService(data as string);
    yield put(EngagementSummaryGraphActions.getTurbocodeStoriesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getTurbocodeStoriesError(error));
    }
  }
}

export function* getLearningSummaryPractitionerDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: ISummaryPractitionerDataResp =
      yield EngagementSummaryGraphService.getLearningCenterSummaryPractitionerService(
        data as ILearningCenterPayload,
      );
    yield put(EngagementSummaryGraphActions.getLearningSummaryPractitionerSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getLearningSummaryPractitionerError(error));
    }
  }
}

export function* getCourseStatusPractitionerDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const { data } = action;
    const response: IDashboardCourseStatusResponse[] =
      yield EngagementSummaryGraphService.getCoursesStatusPractitionerService(
        data as ILearningCenterPayload,
      );
    yield put(EngagementSummaryGraphActions.getCourseStatusPractitionerSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCourseStatusPractitionerError(error));
    }
  }
}

export function* getLegacyDBDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const data = action?.data as string;
    const response: ILegacyDbData[] = yield EngagementSummaryGraphService.getLegacyView(
      data as string,
    );
    yield put(EngagementSummaryGraphActions.getLegacyViewSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getLegacyViewError(error));
    }
  }
}

export function* getLegacyGraphDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const data = action?.data as string;
    const response: ILegacyGraphData = yield EngagementSummaryGraphService.getLegacyGraph(data);
    yield put(EngagementSummaryGraphActions.getLegacyGraphSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getLegacyGraphError(error));
    }
  }
}

//Digital Tester:

export function* getWorkflowsListDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IWorkflowListPayload;
    const response: IWorkflowResponse = yield EngagementSummaryGraphService.getWorkflowListService(
      payload as IWorkflowListPayload,
    );
    yield put(EngagementSummaryGraphActions.getWorkflowsListSuccess(response?.data));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getWorkflowsListError(error));
    }
  }
}

export function* getWorkflowMetricDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IWorkflowPayload;
    const response: IExecutionMetricDetailsResponse =
      yield EngagementSummaryGraphService.getWorkflowMetricsService(payload as IWorkflowPayload);
    yield put(EngagementSummaryGraphActions.getWorkflowMetricDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getWorkflowMetricDetailsError(error));
    }
  }
}

export function* getWorkflowReportsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IWorkflowPayload;
    const response: IWorkflowReportResponse =
      yield EngagementSummaryGraphService.getWorkflowReportsService(payload as IWorkflowPayload);
    yield put(EngagementSummaryGraphActions.getWorkflowReportSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getWorkflowReportError(error));
    }
  }
}

export function* getAlertsIssuesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IDEPAlertScanPayload;
    const response: IDEPAlertScanResponse =
      yield EngagementSummaryGraphService.getDEPScanAlertService(payload as IDEPAlertScanPayload);
    yield put(EngagementSummaryGraphActions.successDepAlertsScanData(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.errorDepAlertsScanData(error));
    }
  }
}

export function* getDEPCodeSpacesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ICodespacesResponse[] =
      yield EngagementSummaryGraphService.getDEPCodeSpacesService(payload as string);
    yield put(EngagementSummaryGraphActions.getDepCodesSpacesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDepCodesSpacesError(error));
    }
  }
}

export function* getProblemInsightsRunListWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IProblemInsightsAssignmentGroupData[] =
      yield EngagementSummaryGraphService.getProblemInsightsRunListService(payload);
    yield put(EngagementSummaryGraphActions.getRunAssignmentListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getRunAssignmentListError(error));
    }
  }
}

export function* getProblemInsightsRunDetailsWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IProblemInsightsPayload;
    const response: IProblemInsightsRunDetailsData =
      yield EngagementSummaryGraphService.getProblemInsightsRunDetailsService(payload);
    yield put(EngagementSummaryGraphActions.getRunDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getRunDetailsError(error));
    }
  }
}

export function* getproblemgroupoverviewWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IProblemInsightsPayload;
    const response: IProblemGroupOverviewResponse =
      yield EngagementSummaryGraphService.getproblemgroupoverviewService(payload);
    yield put(EngagementSummaryGraphActions.getProblemGroupOverviewSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getProblemGroupOverviewError(error));
    }
  }
}

export function* kmsApplicationsWorker(action: EngagementSummaryGraphAction) {
  try {
    const response: IKMSResponse[] = yield EngagementSummaryGraphService.kmsApplicationsService();
    yield put(EngagementSummaryGraphActions.getKMSApplicationsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getKMSApplicationsError(error));
    }
  }
}

export function* getIssueSummaryDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IIssueSummaryResponse[] =
      yield EngagementSummaryGraphService.getIssueSummaryService(payload);
    yield put(EngagementSummaryGraphActions.issueSummaryDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.issueSummaryDataError(error));
    }
  }
}

export function* getRiskSummaryDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IRiskSummaryResponse[] =
      yield EngagementSummaryGraphService.getRiskSummaryService(payload);
    yield put(EngagementSummaryGraphActions.riskSummaryDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.riskSummaryDataError(error));
    }
  }
}

export function* getActiveSummaryDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IActiveSummaryResponse =
      yield EngagementSummaryGraphService.getActiveSummaryService(payload);
    yield put(EngagementSummaryGraphActions.activeSummaryDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.activeSummaryDataError(error));
    }
  }
}

export function* getTransitionsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ITransitionTableResponseData[] =
      yield EngagementSummaryGraphService.getTransitionService(payload);
    yield put(EngagementSummaryGraphActions.transitionsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.transitionsDataError(error));
    }
  }
}
//CBO-2901_AI_AUTO_NEW_CODE : Added Saga Worker Functions for AppProgressionData, ServerProgressionData and ArchetypeList

export function* getAppProgressionDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IMigrationPayload;
    const response: IAppsProgressionResponse[] =
      yield EngagementSummaryGraphService.getAppsProgressionService(payload);
    yield put(EngagementSummaryGraphActions.getAppProgressionSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getAppProgressionError(error));
    }
  }
}

export function* getServersProgressionDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IMigrationPayload;
    const response: IServersProgressionResponse[] =
      yield EngagementSummaryGraphService.getServersProgressionService(payload);
    yield put(EngagementSummaryGraphActions.getServersProgressionSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getServersProgressionError(error));
    }
  }
}

export function* getArchetypeListDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const data = action?.data as IMigrationPayload;
    const response: string[] = yield EngagementSummaryGraphService.getArchetypeListService(data);
    yield put(
      EngagementSummaryGraphActions.getArchetypeListSuccess({
        archetypes: response,
        type: data?.type,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getArchetypeListError(error));
    }
  }
}

//CBO-2901_AI_AUTO_NEW_CODE : Added new saga functions for AppProgressionMonthlyData, ServerProgressionMonthlyData
export function* getAppProgressionMonthlyDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IMigrationPayload;
    const response: IAppsProgressionMonthlyResponse[] =
      yield EngagementSummaryGraphService.getAppsProgressionMonthlyService(payload);
    yield put(EngagementSummaryGraphActions.getAppProgressionMonthlyDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getAppProgressionMonthlyDataError(error));
    }
  }
}
export function* getServerProgressionMonthlyDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IMigrationPayload;
    const response: IServersProgressionMonthlyResponse[] =
      yield EngagementSummaryGraphService.getServersProgressionMonthlyService(payload);
    yield put(EngagementSummaryGraphActions.getServerProgressionMonthlyDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getServerProgressionMonthlyDataError(error));
    }
  }
}

export function* getTCOInsightsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ITCOInsightsResponse =
      yield EngagementSummaryGraphService.getTcoInsightsService(payload);
    yield put(EngagementSummaryGraphActions.getTCOInsightsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getTCOInsightsError(error));
    }
  }
}

export function* getCostTrendsDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ICostTrendResponse[] =
      yield EngagementSummaryGraphService.getCostTrendsService(payload);
    yield put(EngagementSummaryGraphActions.getCostTrendsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCostTrendsError(error));
    }
  }
}

export function* getCostAllocationSummaryDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ICostAllocationSummaryResponse =
      yield EngagementSummaryGraphService.getCostAllocationSummaryService(payload);
    yield put(EngagementSummaryGraphActions.getCostAllocationSummarySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCostAllocationSummaryError(error));
    }
  }
}

export function* getBlockersandCausesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as IMigrationPayload;
    const response: IBlockersAndCausesResponse =
      yield EngagementSummaryGraphService.getBlockersandCausesService(payload);
    yield put(EngagementSummaryGraphActions.getBlockersandCausesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getBlockersandCausesError(error));
    }
  }
}

export function* getAllocationTrendDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IAllocationTrendResponse =
      yield EngagementSummaryGraphService.getAllocationTrendService(payload);
    yield put(EngagementSummaryGraphActions.getAllocationTrendSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getAllocationTrendError(error));
    }
  }
}

export function* getCloudCostSummaryCostTrendDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ICloudCostSummaryCostTrendResponse[] =
      yield EngagementSummaryGraphService.getCloudCostSummaryCostTrendService(payload);
    yield put(EngagementSummaryGraphActions.getCloudCostSummaryCostTrendSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getCloudCostSummaryCostTrendError(error));
    }
  }
}

// CBO-3061_AI_AUTO_ENHANCE_CODE:Created new saga worker functions for getting the data for the server by upgrade graphs
export function* getServersByUpgradeDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IServersByUpgradeResponse[] =
      yield EngagementSummaryGraphService.getServersByUpgradeService(payload);
    yield put(EngagementSummaryGraphActions.getServersByUpgradeSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getServersByUpgradeError(error));
    }
  }
}

export function* getDispositionsByWaveDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IDispositionsByWaveData =
      yield EngagementSummaryGraphService.getDispositionByWaveService(payload);
    yield put(EngagementSummaryGraphActions.getDispositionsByWaveSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDispositionsByWaveError(error));
    }
  }
}

//CBO-4166_AI_AUTO_ENHANCE_CODE generate saga worked function for the following functionname getDefectAgeingDataWorker, getNumberOfTestCasesDataWorker, getSeverityVsCreatedOnDataWorker, getTableCountDataWorker and getTestRunStatusDataWorker
export function* getDefectAgeingDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: IDefectAgeingResponse =
      yield EngagementSummaryGraphService.getDefectAgeingService(payload);
    yield put(EngagementSummaryGraphActions.getDefectAgeingSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getDefectAgeingError(error));
    }
  }
}

export function* getNumberOfTestCasesDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: INumberOfTestCasesResponse =
      yield EngagementSummaryGraphService.getNumberOfTestCasesService(payload);
    yield put(EngagementSummaryGraphActions.getNumberOfTestCasesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getNumberOfTestCasesError(error));
    }
  }
}

export function* getSeverityVsCreatedOnDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ISeverityVsCreatedOnResponse =
      yield EngagementSummaryGraphService.getSeverityVsCreatedOnService(payload);
    yield put(EngagementSummaryGraphActions.getSeverityVsCreatedOnSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getSeverityVsCreatedOnError(error));
    }
  }
}

export function* getTableCountDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ITableCountResponse =
      yield EngagementSummaryGraphService.getTableCountService(payload);
    yield put(EngagementSummaryGraphActions.getTableCountSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getTableCountError(error));
    }
  }
}

export function* getTestRunStatusDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ITestRunStatusResponse =
      yield EngagementSummaryGraphService.getTestRunStatusService(payload);
    yield put(EngagementSummaryGraphActions.getTestRunStatusSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getTestRunStatusError(error));
    }
  }
}

export function* getTaxonomyEditorDataWorker(action: EngagementSummaryGraphAction) {
  try {
    const payload = action?.data as string;
    const response: ITaxonomyEditorData[] =
      yield EngagementSummaryGraphService.getTaxonomyEditorService(payload);
    yield put(EngagementSummaryGraphActions.getTaxonomyEditorDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(EngagementSummaryGraphActions.getTaxonomyEditorDataError(error));
    }
  }
}

export function* engagementSummaryGraphWatcher() {
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_LIFT_SHIFT_CHART_STARTED,
    getLiftAndShiftWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_INITIATIVE_STARTED,
    getJiraInitiativeDataWorker,
  );
  yield takeEvery(EngagementSummaryGraphActionTypes.GET_JIRA_EPICS_STARTED, getJiraEpicsDataWorker);
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_CURRENT_SPRINT_STARTED,
    getCurrentSprintDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ALL_SQL_GRAPH_DATA_START,
    getAllSqlDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DML_SQL_GRAPH_DATA_START,
    getDMLSqlDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_OTHER_SQL_GRAPH_DATA_START,
    getOtherSqlDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DCMPTN_GRAPH_DATA_START,
    getDecompositionDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_MISSING_ARTIFACT_DATA_START,
    getMissingArtifactsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ANALYZED_ARTIFACT_DATA_START,
    getAnalysedArtifactsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_NUMBER_LOC_DATA_START,
    getLOCArtifactsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_START,
    getCodeQualityLowDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_START,
    getCodeQualityMediumDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_START,
    getCodeQualityHighDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_START,
    getCodeQualityVeryHighDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_METRICS_MODULE_DATA_START,
    getMetricsModuleDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_PHYSICAL_FILES_DATA_START,
    getPhysicalFilesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_PHYSICAL_LOC_DATA_START,
    getPhysicalLOCDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_SOURCE_LOC_DATA_START,
    getSourceLOCDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_COMMENT_LOC_DATA_START,
    getCommentLOCDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DEAD_CODE_DATA_START,
    getDeadCodeDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_MISSING_MODULE_DATA_START,
    getMissingModulesDataWorker,
  );
  yield takeEvery(EngagementSummaryGraphActionTypes.GET_ERRORS_DATA_START, getErrorsDataWorker);
  // yield takeEvery(
  //   EngagementSummaryGraphActionTypes.GET_JIRA_TASK_LIST_STARTED,
  //   getJiraTaskListDataWorker,
  // ); // Cloud Migration Fecthing Tasks based on epic Id
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_TASK_LIST_STARTED,
    getJiraTableTaskListDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_FCC_START,
    getCodeQualityLowDataForFccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_FCC_START,
    getCodeQualityMediumDataForFccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_FCC_START,
    getCodeQualityHighDataForFccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_FCC_START,
    getCodeQualityVeryHighDataForFccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_LCC_START,
    getCodeQualityLowDataForLccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_MEDIUM_GRAPH_DATA_FOR_LCC_START,
    getCodeQualityMediumDataForLccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_HIGH_GRAPH_DATA_FOR_LCC_START,
    getCodeQualityHighDataForLccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_VERY_HIGH_GRAPH_DATA_FOR_LCC_START,
    getCodeQualityVeryHighDataForLccWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CODE_QUALITY_LOW_GRAPH_DATA_FOR_SC_START,
    getCodeQualityLowDataForScWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_INITIATIVE_STARTED,
    getJiraTableInitiativeDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_APPLICATION_DISPOSITIONING_STARTED,
    getApplicationDepositionDataWorker,
  );
  yield takeEvery(EngagementSummaryGraphActionTypes.GET_WAVE_PLANE_STARTED, getWavePlanDataWorker);
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_EPICS_STARTED,
    getJiraTableEpicsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_JIRA_TABLE_STORY_LIST_STARTED,
    getJiraTableStoryListDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_INVENTORY_SURVEY_DATA_START,
    getInventorySurveyMetricsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_BUSINESS_SURVEY_DATA_START,
    getBusinessSurveyMetricsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DISCOVERY_METRICS_DATA_START,
    getDiscoveryMetricsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.POST_INCIDENT_SUMMARY_TRENDS_STARTED,
    postIncidentSummaryAndTrendsWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_INCIDENT_DETAILS_STARTED,
    postIncidentDetailsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.SET_KEY_ASSET_CONFIG_STARTED,
    postKeyAssetConfigDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CARBON_FOOT_PRINT_START,
    getCarbonFootPrintDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CARBON_CREDITS_START,
    getCarbonCreditsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ENERGY_SAVINGS_START,
    getEnergySavingsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TURBOCODE_STORIES_START,
    getTurbocodeStoriesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_LEARNING_CENTER_SUMMARY_START,
    getLearningSummaryPractitionerDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_COURSES_STATUS_START,
    getCourseStatusPractitionerDataWorker,
  );

  yield takeEvery(EngagementSummaryGraphActionTypes.GET_LEGACY_DB_STARTED, getLegacyDBDataWorker);
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_LEGACY_GRAPH_STARTED,
    getLegacyGraphDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_WORKFLOW_LIST_STARTED,
    getWorkflowsListDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_WORKFLOW_METRICS_STARTED,
    getWorkflowMetricDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_WORKFLOW_REPORTS_STARTED,
    getWorkflowReportsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DEPSCAN_ALERTS_STARTED,
    getAlertsIssuesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DEP_CODES_SPACES_STARTED,
    getDEPCodeSpacesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_RUN_ASSIGNMENT_LIST_STARTED,
    getProblemInsightsRunListWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_RUN_DETAILS_STARTED,
    getProblemInsightsRunDetailsWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_PROBLEM_GROUP_OVERVIEW_STARTED,
    getproblemgroupoverviewWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_KMS_RESPONSE_STARTED,
    kmsApplicationsWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ISSUE_SUMMARY_DATA_STARTED,
    getIssueSummaryDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_RISK_SUMMARY_DATA_STARTED,
    getRiskSummaryDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ACTIVE_SUMMARY_DATA_STARTED,
    getActiveSummaryDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TRANSITIONS_DATA_STARTED,
    getTransitionsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_DATA_STARTED,
    getAppProgressionDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_DATA_STARTED,
    getServersProgressionDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ARCHETYPE_LIST_STARTED,
    getArchetypeListDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_APPS_PROGRESSION_MONTHLY_DATA_STARTED,
    getAppProgressionMonthlyDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_SERVERS_PROGRESSION_MONTHLY_DATA_STARTED,
    getServerProgressionMonthlyDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TCO_INSIGHTS_STARTED,
    getTCOInsightsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_COST_TRENDS_STARTED,
    getCostTrendsDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_COST_ALLOCATION_SUMMARY_STARTED,
    getCostAllocationSummaryDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_BLOCKERS_CAUSES_DATA_STARTED,
    getBlockersandCausesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_ALLOCATION_TRENDS_DATA_STARTED,
    getAllocationTrendDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_CLOUD_COST_SUMMARY_COST_TREND_STARTED,
    getCloudCostSummaryCostTrendDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_SERVERS_BY_UPGRADE_STARTED,
    getServersByUpgradeDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DISPOSITIONS_BY_WAVE_STARTED,
    getDispositionsByWaveDataWorker,
  );

  //CBO-4166_AI_AUTO_ENHANCE_CODE add new saga function for the following actions
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_DEFECT_AGEING_STARTED,
    getDefectAgeingDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_NUMBER_OF_TEST_CASES_STARTED,
    getNumberOfTestCasesDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_SEVERITY_VS_CREATEDON_STARTED,
    getSeverityVsCreatedOnDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TABLE_COUNT_STARTED,
    getTableCountDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TEST_RUN_STATUS_STARTED,
    getTestRunStatusDataWorker,
  );
  yield takeEvery(
    EngagementSummaryGraphActionTypes.GET_TAXONOMY_EDITOR_DATA_STARTED,
    getTaxonomyEditorDataWorker,
  );
}
