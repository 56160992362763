import { put, select, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../../components/Notifications/NotificationsConstants';
import {
  IAssetDetailPayload,
  IEngagementAssetsObject,
  IEngagementAssetsPayload,
  IMarketplaceAssetModal,
  IMarketplaceAssetPayload,
} from '../../../models/cbo/CboMarketplaceModels';
import marketplaceService from '../../../services/cbo/CboMarketplace/CboMarketplaceService';
import {
  CboMarketplaceActions,
  CboMarketplaceAction,
  CboMarketplaceActionTypes,
} from '../../actions/cbo/CboMarketplaceAction';
import { ToastActions } from '../../actions/ToastActions';

export function* getMandatoryAssetssWorker(action: CboMarketplaceAction) {
  try {
    const { isEngagement } = action.data as IMarketplaceAssetPayload;
    const response: IEngagementAssetsObject = yield marketplaceService.getEngagementMandatoryAssets(
      action.data as IMarketplaceAssetPayload,
    );
    yield put(CboMarketplaceActions.getMarketplaceMandatoryAssetsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceActions.getMarketplaceMandatoryAssetsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getRecommendedAssetssWorker(action: CboMarketplaceAction) {
  try {
    const { isEngagement } = action.data as IMarketplaceAssetPayload;
    const response: IEngagementAssetsObject =
      yield marketplaceService.getEngagementRecommendedAssets(
        action.data as IMarketplaceAssetPayload,
      );
    yield put(CboMarketplaceActions.getMarketplaceRecommendedAssetsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceActions.getMarketplaceRecommendedAssetsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* addEngagementAssetWorker(action: CboMarketplaceAction) {
  try {
    yield marketplaceService.addEngagmentAsset(action.data as IEngagementAssetsPayload);
    yield put(CboMarketplaceActions.addMarketplaceAssetsSuccess());
    yield put(
      ToastActions.showToast({
        description: 'Assets configured successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(CboMarketplaceActions.addMarketplaceAssetsError(error));
    }
  }
}

export function* getEngagementAssetDetailsWorker(action: CboMarketplaceAction) {
  try {
    const data = action.data as IAssetDetailPayload;
    const response: IMarketplaceAssetModal =
      yield marketplaceService.getEngagementAssetDetails(data);
    yield put(CboMarketplaceActions.getMarketplaceAssetDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CboMarketplaceActions.getMarketplaceAssetDetailsError(error));
      yield put(
        ToastActions.showToast({
          message: 'Error!!!',
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* cboMarketplaceWatcher() {
  yield takeEvery(
    CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_STARTED,
    getMandatoryAssetssWorker,
  );
  yield takeEvery(
    CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_STARTED,
    getRecommendedAssetssWorker,
  );
  yield takeEvery(
    CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_STARTED,
    addEngagementAssetWorker,
  );
  yield takeEvery(
    CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_STARTED,
    getEngagementAssetDetailsWorker,
  );
}
