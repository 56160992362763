/* eslint-disable max-lines */
import { Reducer } from 'redux';
import {
  ICapabilitiesByPhaseResponse,
  IMostVisitedCapabilitiesResponse,
  TrafficAnalyticsAction,
  TrafficAnalyticsActionTypes,
} from '../../actions/WebAnalytics/TrafficAnalyticsActions';
import TrafficAnalyticsState from '../../state/TrafficAnalyticsState';
import ITrafficAnalyticsState from '../../state/TrafficAnalyticsState';
import {
  IAnalyticsUserListResponse,
  IEngagementKeyAssetsData,
  IFiscalYear,
  IPursuitAnalyticsResponse,
  IPursuitEngagementResponse,
  IPursuitsEngagementAccumulativeResponse,
  IPursuitsEngagementsOverallSummaryResponse,
  IPursuitsEngReportsResponse,
  IUserActivityListResponse,
  IUserAnalyticsPayload,
} from '../../../models/cbo/cboWebAnalyticsModel';
import { overallSummaryLabels } from '../../../../containers/CBOWebAnanlytics/CBOWebAnalyticsConstants';

const defaultPursuitEngagementsData: IPursuitEngagementResponse = {
  engagementCount: 0,
  pursuitCount: 0,
  pursuitEngagementData: [],
};

export const initialState: ITrafficAnalyticsState = {
  error: '',
  loading: false,
  loadingMostCapabilitiesPerPhases: false,
  loadingCapabilitiesByPhases: false,
  mostCapabilitiesPerPhasesData: [],
  mostCapabilitiesPerPhasesCompleted: false,
  capabilitiesByPhaseData: [],
  capabilitiesByPhaseDataCompleted: false,
  keyAssetCountData: [],
  loadingkeyAssetCountData: false,
  keyAssetCountDataCompleted: false,
  pursuitEngagementsData: defaultPursuitEngagementsData,
  pursuitEngagementsDataLoading: false,
  analyticsUserListData: {} as IAnalyticsUserListResponse,
  analyticsUserListDataloading: true,
  allAnalyticsUserListData: {} as IAnalyticsUserListResponse,
  userActivityData: {} as IUserActivityListResponse,
  userActivityDataloading: true,
  activePursuitsAnalyticsData: {} as IPursuitAnalyticsResponse,
  allActivePursuitsAnalyticsData: {} as IPursuitAnalyticsResponse,
  activePursuitsAnalyticsDataLoading: true,
  pursuitOverallSummaryData: {} as IPursuitsEngagementsOverallSummaryResponse,
  engagementOverallSummaryData: {} as IPursuitsEngagementsOverallSummaryResponse,
  pursuitOverallSummaryCompleted: true,
  engagementOverallSummaryCompleted: true,
  pursuitAccumulativeData: [] as IPursuitsEngagementAccumulativeResponse[],
  pursuitAccumulativeDataCompleted: true,
  engagementAccumulativeData: [] as IPursuitsEngagementAccumulativeResponse[],
  engagementAccumulativeDataCompleted: true,
  fiscalYearMetaDataResponse: [] as IFiscalYear[],
  fiscalYearMetaDataResponseCompleted: true,
  pursuitEngagementsReportsResponse: {} as IPursuitsEngReportsResponse,
  pursuitEngagementsReportsResponseCompleted: true,
};

export const TrafficAnalyticsReducer: Reducer<TrafficAnalyticsState> = (
  state = initialState,
  action: TrafficAnalyticsAction,
) => {
  switch (action.type) {
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_START:
      return {
        ...state,
        loadingMostCapabilitiesPerPhases: true,
        mostCapabilitiesPerPhasesCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_SUCCESS:
      return {
        ...state,
        loadingMostCapabilitiesPerPhases: false,
        mostCapabilitiesPerPhasesCompleted: true,
        mostCapabilitiesPerPhasesData: action.data as IMostVisitedCapabilitiesResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_ERROR:
      return {
        ...state,
        loadingMostCapabilitiesPerPhases: false,
        mostCapabilitiesPerPhasesCompleted: true,
        error: action.data as Error,
      };

    case TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_START:
      return {
        ...state,
        loadingCapabilitiesByPhases: true,
        capabilitiesByPhaseDataCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_SUCCESS:
      return {
        ...state,
        loadingCapabilitiesByPhases: false,
        capabilitiesByPhaseDataCompleted: true,
        capabilitiesByPhaseData: action.data as ICapabilitiesByPhaseResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_ERROR:
      return {
        ...state,
        loadingCapabilitiesByPhases: false,
        capabilitiesByPhaseDataCompleted: true,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_START:
      return {
        ...state,
      };
    case TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_SUCCESS:
      return {
        ...state,
      };
    case TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    case TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_START:
      return {
        ...state,
        loadingkeyAssetCountData: true,
        keyAssetCountDataCompleted: false,
        keyAssetCountData: [],
      };
    case TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_SUCCESS:
      return {
        ...state,
        loadingkeyAssetCountData: false,
        keyAssetCountDataCompleted: true,
        keyAssetCountData: action.data as IEngagementKeyAssetsData[],
      };
    case TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_ERROR:
      return {
        ...state,
        loadingkeyAssetCountData: false,
        keyAssetCountDataCompleted: true,
        error: action.data as Error,
      };

    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_START:
      return {
        ...state,
        pursuitEngagementsDataLoading: true,
        pursuitEngagementsData: {} as IPursuitEngagementResponse,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        pursuitEngagementsDataLoading: false,
        pursuitEngagementsData: action.data as IPursuitEngagementResponse,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_ERROR:
      return {
        ...state,
        pursuitEngagementsDataLoading: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.RESET_ANALYTICS_DATA:
      return {
        ...state,
        keyAssetCountData: [],
        capabilitiesByPhaseData: [],
        mostCapabilitiesPerPhasesData: [],
        pursuitEngagementsData: defaultPursuitEngagementsData,
        fiscalYearMetaDataResponse: [] as IFiscalYear[],
        pursuitOverallSummaryData: {} as IPursuitsEngagementsOverallSummaryResponse,
        engagementOverallSummaryData: {} as IPursuitsEngagementsOverallSummaryResponse,
        pursuitAccumulativeData: [] as IPursuitsEngagementAccumulativeResponse[],
        engagementAccumulativeData: [] as IPursuitsEngagementAccumulativeResponse[],
        pursuitOverallSummaryCompleted: true,
        engagementOverallSummaryCompleted: true,
        pursuitAccumulativeDataCompleted: true,
        engagementAccumulativeDataCompleted: true,
        pursuitEngagementsReportsResponse: {} as IPursuitsEngReportsResponse,
        pursuitEngagementsReportsResponseCompleted: true,
      };
    //CBO-3309:generated reducer cases for user activity and user list
    case TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_START:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          allAnalyticsUserListData: {} as IAnalyticsUserListResponse,
        };
      }
      return {
        ...state,
        analyticsUserListDataloading: true,
        analyticsUserListData: {} as IAnalyticsUserListResponse,
      };
    case TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_SUCCESS:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          allAnalyticsUserListData: action.data as IAnalyticsUserListResponse,
        };
      }
      return {
        ...state,
        analyticsUserListDataloading: false,
        analyticsUserListData: action.data as IAnalyticsUserListResponse,
      };
    case TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_ERROR:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          error: action.data as Error,
        };
      }
      return {
        ...state,
        analyticsUserListDataloading: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_START:
      return {
        ...state,
        userActivityDataloading: true,
        userActivityData: {} as IUserActivityListResponse,
      };
    case TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userActivityDataloading: false,
        userActivityData: action.data as IUserActivityListResponse,
      };
    case TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_ERROR:
      return {
        ...state,
        userActivityDataloading: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_START:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          allActivePursuitsAnalyticsData: {} as IPursuitAnalyticsResponse,
        };
      }
      return {
        ...state,
        activePursuitsAnalyticsDataLoading: true,
        activePursuitsAnalyticsData: {} as IPursuitAnalyticsResponse,
      };
    case TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_SUCCESS:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          allActivePursuitsAnalyticsData: action.data as IPursuitAnalyticsResponse,
        };
      }
      return {
        ...state,
        activePursuitsAnalyticsDataLoading: false,
        activePursuitsAnalyticsData: action.data as IPursuitAnalyticsResponse,
      };
    case TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_ERROR:
      if ((action?.data as IUserAnalyticsPayload)?.isExport) {
        return {
          ...state,
          error: action.data as Error,
        };
      }
      return {
        ...state,
        activePursuitsAnalyticsDataLoading: false,
        error: action.data as Error,
      };

    case TrafficAnalyticsActionTypes.GET_OVERALL_SUMMARY_STATUS_REPORT_START:
      if (action.data === overallSummaryLabels.pursuits?.toLowerCase()?.slice(0, -1)) {
        return {
          ...state,
          pursuitOverallSummaryData: {} as IPursuitsEngagementsOverallSummaryResponse,
          pursuitOverallSummaryCompleted: false,
        };
      } else {
        return {
          ...state,
          pursuitOverallSummaryData: action?.data as IPursuitsEngagementsOverallSummaryResponse,
          engagementOverallSummaryCompleted: false,
        };
      }
    case TrafficAnalyticsActionTypes.GET_OVERALL_SUMMARY_STATUS_REPORT_SUCCESS:
      if (
        (action.data as IPursuitsEngagementsOverallSummaryResponse)?.reportType ===
        overallSummaryLabels.pursuits?.toLowerCase()?.slice(0, -1)
      ) {
        return {
          ...state,
          pursuitOverallSummaryData: action.data as IPursuitsEngagementsOverallSummaryResponse,
          pursuitOverallSummaryCompleted: true,
        };
      }
      return {
        ...state,
        engagementOverallSummaryData: action.data as IPursuitsEngagementsOverallSummaryResponse,
        engagementOverallSummaryCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_OVERALL_SUMMARY_STATUS_REPORT_ERROR:
      return {
        ...state,
        pursuitOverallSummaryCompleted: true,
        engagementOverallSummaryCompleted: true,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ACCUMULATIVE_DATA_START:
      return {
        ...state,
        pursuitAccumulativeData: [],
        pursuitAccumulativeDataCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ACCUMULATIVE_DATA_SUCCESS:
      return {
        ...state,
        pursuitAccumulativeData: action.data as IPursuitsEngagementAccumulativeResponse[],
        pursuitAccumulativeDataCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ACCUMULATIVE_DATA_ERROR:
      return {
        ...state,
        pursuitAccumulativeDataCompleted: true,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_ENGAGEMENT_ACCUMULATIVE_DATA_START:
      return {
        ...state,
        engagementAccumulativeData: [],
        engagementAccumulativeDataCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_ENGAGEMENT_ACCUMULATIVE_DATA_SUCCESS:
      return {
        ...state,
        engagementAccumulativeData: action.data as IPursuitsEngagementAccumulativeResponse[],
        engagementAccumulativeDataCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_ENGAGEMENT_ACCUMULATIVE_DATA_ERROR:
      return {
        ...state,
        engagementAccumulativeDataCompleted: true,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_FISCAL_YEARS_METADATA_START:
      return {
        ...state,
        fiscalYearMetaDataResponse: [] as IFiscalYear[],
        fiscalYearMetaDataResponseCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_FISCAL_YEARS_METADATA_SUCCESS:
      return {
        ...state,
        fiscalYearMetaDataResponse: action.data as IFiscalYear[],
        fiscalYearMetaDataResponseCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_FISCAL_YEARS_METADATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
        fiscalYearMetaDataResponseCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENG_REPORTS_TABLE_START:
      return {
        ...state,
        pursuitEngagementsReportsResponse: {} as IPursuitsEngReportsResponse,
        pursuitEngagementsReportsResponseCompleted: false,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENG_REPORTS_TABLE_SUCCESS:
      return {
        ...state,
        pursuitEngagementsReportsResponse: action.data as IPursuitsEngReportsResponse,
        pursuitEngagementsReportsResponseCompleted: true,
      };
    case TrafficAnalyticsActionTypes.GET_PURSUIT_ENG_REPORTS_TABLE_ERROR:
      return {
        ...state,
        error: action.data as Error,
        pursuitEngagementsReportsResponseCompleted: true,
      };

    default:
      return state;
  }
};
