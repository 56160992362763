/**
 * API routes for the application.
 *
 * This file contains the definition of API routes used in the application. It provides a centralized
 * location to manage and access the various API endpoints used by the application.
 *
 * Example usage:
 *
 * import { API_ROUTE_CBO } from './libs/services/ApiRoute';
 *
 * // Accessing a specific API route
 * const loginRoute = API_ROUTE_CBO.CBO_LOGIN;
 *
 * // Using the API route in an API call
 * fetch(loginRoute)
 *   .then(response => {
 *     // Handle the response
 *   })
 *   .catch(error => {
 *     // Handle the error
 *   });
 */
/**
 * API routes for the application.
 */
export const API_ROUTE_CBO = {
  CBO_LOGIN: 'login',
  GET_USER_PERMISSIONS: 'user/permission',
  CWB: {
    GET_ASSESSMENT_DATA: 'cwb/configurecomponents',
    CWB_SSO_URL: 'cwb/users/ssoLoginUrl',
  },
  GET_SESSION_TOKEN_DATA: 'user-activity',
  WORKFLOW: 'workflow',
  multiCloudYaml: 'multi-cloud-landing-zone/yaml',
  POST_CUSTOM_LOGIN: 'client/login',
  POST_CHANGE_PASSWORD: 'client/resetPassword',
  GET_PURSUITS: 'engagement/list',
  GET_ENGAGEMENTS: 'project/list',
  GET_JOURNEY_CAPABILITY: 'configure/capability',
  GET_PURSUIT_CONFIG: 'engagement/configure/:engagementId',
  GET_ENGAGEMENT_CONFIG: 'project/:engagementId',
  SAVE_PURSUIT_CONFIG: 'engagement/configure',
  VALIDATE_PURSUIT_NAME: 'pursuit/validatepursuitname',
  SAVE_ENGAGEMENT_CONFIG: 'project',
  DELETE_ENGAGEMENT: 'engagement/configure',
  GET_CLIENTS: 'client',
  GET_CLIENT_ENGAGEMENTS: 'engagement/client/:clientId',
  GET_CLIENT_ENG_MAPPINGS: 'engagement/client',
  SAVE_CLIENT: 'client',
  SAVE_ENGAGEMENT: 'engagement',
  DELETE_CLIENT: 'client/:clientId',
  GET_USER_LIST: 'admin/user',
  GET_MOST_VISITED_DATA: 'analytics/page',
  POST_VISITED_DATA: 'analytics/page',
  GET_CLOUD_ANALYTICS_DATA: 'cloud',
  GET_CLOUD_ASSESSMENT_DATA: 'cloud-assessment',
  GET_METRIC_ANALYTICS: 'analytics/engagement/metric',
  KEY_ASSETS_COUNT: '',
  NEW_VS_RETURNING_USERS: 'new-vs-returning-users',
  GET_USERS_VISITS_DATA: 'visits',
  GET_PAGE_VIEWS_DATA: 'page-views',
  BOUNCE_RATE_DATA: 'bounce-rate',
  GET_USER_ACTIVITY_DATA: 'analytics/user-activity',
  GET_ACTIVE_USERS_DATA: 'analytics/active-users/',
  GET_RBAC_DATA: 'policy',
  GET_ROLE_METADATA: 'roles',
  GET_CWB_AUTH_TOKEN: 'authorise/cwb',
  REFRESH_TOKEN: 'refresh-token',
  GET_ENGAGEMENT_ASSETS: 'project/asset/:engagementId',
  GET_PURSUIT_ASSETS: 'engagement/configure/assets/:engagementId',
  POST_PURSUIT_ASSETS: 'engagement/configure/assets',
  POST_ENGAGEMENT_ASSETS: 'project/asset',
  GET_JOURNEY_BUILDER_LIST: 'journey',
  GET_PREDEFINED_JOURNEY_LIST: 'journey/meta-data',
  GET_CE_PREDEFINED_JOURNEY_LIST: 'journey/metadata',
  GET_JOURNEY_MANDATORY_ASSETS: 'configure/capability/mandatory/assets',
  GET_JOURNEY_RECOMMENDED_ASSETS: 'journey/:journeyId/allAsset',
  POST_JOURNEY_BUILDER_SAVE: 'journey',
  DELETE_DOCUMENT: '',
  POST_SUBMIT_STATUS: 'journey/action',
  GET_ADDED_DOCUMENT_LIST: 'journey/:journeyId/assets',
  JOURNEY_BUILDER: 'journey',
  GET_BUSINESS_OBJECTIVES: 'journey/businessobjectives/metadata',
  GET_CAPABILITY: 'capability',
  GET_FEATURES: 'feature',
  GET_PHASES: 'capability/phase/metadata',
  GET_KEY_ASSETS: 'keyAssets',
  SAVE_CAPABILITY: 'capability',
  GET_LIFT_AND_SHIFT: 'jira/pie-chart',
  GET_JIRA_INITIATIVES: 'jira/initiatives',
  GET_JIRA_EPICS: 'jira/epics',
  GET_JIRA_TASKS: 'jira/tasks',
  GET_JIRA_TASKLIST: 'jira/:phase/taskList',
  POST_CAPABILITY_ORDER: 'engagement/configure/capability/order',
  GET_AD_ACCESS_TOKEN: 'ad_access_token',
  GET_SERVICE_ACCOUNT_AD_TOKEN: 'ad_token',
  GET_ENGAGEMENT_ASSET_DETAILS: 'journey/asset-details/:assetId/assetType/:assetType',
  GET_SQL_DATA: 'projects/:projectId/statements/aggregations',
  GET_DECOMPOSITION_GRAPH_DATA: 'api/v1/projects/:projectId/taxonomies',
  GET_ARTIFACTS_GRAPH_DATA: 'api/v1/projects/:projectId/modules/aggregations',
  POST_CODE_QUALITY: 'api/v1/projects/:projectId/modules/aggregations',
  GET_INNOWAKE_TOKEN: 'innowake-mining/token',
  GET_ASSET_CREDENTIAL: 'keyasset/credential',
  KEYCLOAK_TOKEN: 'protocol/openid-connect/token',
  GET_TABLE_JIRA_INITIATIVES: 'jira/:phase/initiativeList',
  GET_JIRA_TABLE_EPICS: 'jira/:phase/epicList',
  FEATURES: 'configure/engagement/features',
  ASSET_CATEGORY: 'marketplace/assetCategory',
  SYSTEM_TAGS: 'marketplace/tags',
  GET_FILTER_DATA: 'assets/url',
  POST_FILTER_DATA: 'assets/filterurl',
  GET_MARKETPLACE_ASSETS: 'marketplace/search/assets',
  GET_KEY_ASSETS_ICON_LIST: 'key-assets/icons',
  FEEDBACK: 'feedback',
  GET_JOURNEY_FILTER_LIST: 'marketplace/journey/capabilities',
  ADMIN_FEEDBACK: 'feedback/admin',
  GET_KEY_ASSETS_LIST: 'keyAssets',
  GET_ENG_KEY_ASSETS_LIST: 'project/keyAsset',
  GET_IMPORTANT_LINKS: 'dnet/search',
  ENGAGEMENT_CONVERSION: 'pursuit/status',
  GET_PURSUITS_ENGAGEMENT_METRICS: 'analytics/engagement/metric/PURVSENG',
  GET_JIRA_INITIATIVE: 'jira/project/:projectId/:phase?type=initiative',
  GET_PURSUIT_JIRA_INITIATIVE: 'jira/engagement/:pursuitId/:phase?type=initiative',
  GET_JIRA_EPIC: 'jira/project/:projectId/:phase',
  GET_PURSUIT_JIRA_EPIC: 'jira/engagement/:pursuitId/:phase',
  GET_JIRA_TASK: 'jira/project/:projectId/:phase',
  GET_PURSUIT_JIRA_TASK: 'jira/engagement/:pursuitId/:phase',
  GET_JIRA_STORY: 'jira/project/:projectId/:phase',
  GET_PURSUIT_JIRA_STORY: 'jira/engagement/:pursuitId/:phase',
  GET_JIRA_SPRINT: 'jira/project/:projectId/:phase?type=sprint',
  GET_PURSUIT_JIRA_SPRINT: 'jira/engagement/:pursuitId/:phase?type=sprint',

  POST_OPERATE_EDGE_INCIDENT_DATA: 'operateedge/incident/getIncidentData',
  POST_OPERATE_EDGE_ALL_GRAPHS: 'operateedge/incident/getIncidentAllGraphsData',

  GET_CLIENT_USER_MAPPING: 'admin/user/:userId',
  SHOW_USER_CLIENT: 'admin/user/:userId',
  UPDATE_KEY_ASSET_CONFIG: 'project/keyasset',
  UPDATE_PURSUIT_KEY_ASSET_CONFIG: 'engagement/keyasset/config',

  //CWB Eng specific
  GET_ENG_FIVE_YR_PL_DATA: 'cwb/project/:projectId/exportchartdata',
  GET_ENG_CHART_DATA: 'cwb/project/:projectId/bauvscloud',
  GET_ENG_COMPUTE_COST_DATA: 'cwb/project/:projectId/computecost',
  GET_ENG_STORAGE_COST_DATA: 'cwb/project/:projectId/storagecost',
  GET_ENG_HEATMAP_DATA: 'cwb/project/:projectId/heatmap',
  GET_ENG_ASSESSMENT_DATA: 'cwb/project/:projectId/configurecomponents', //pending
  GET_ENG_CATEGORIES_DATA: 'cwb/project/:projectId/assessmentdetailspopup', //pending
  GET_ENG_GREEN_CLOUD_DATA: 'cwb/project/:projectId/greencloud',

  //Pursuit Specific for CWB
  GET_PURSUIT_FIVE_YR_PL_DATA: 'cwb/engagement/:pursuitId/exportchartdata',
  GET_PURSUIT_CHART_DATA: 'cwb/engagement/:pursuitId/bauvscloud',
  GET_PURSUIT_COMPUTE_COST_DATA: 'cwb/engagement/:pursuitId/computecost',
  GET_PURSUIT_STORAGE_COST_DATA: 'cwb/engagement/:pursuitId/storagecost',
  GET_PURSUIT_HEATMAP_DATA: 'cwb/engagement/:pursuitId/heatmap',
  GET_PURSUIT_CATEGORIES_DATA: 'cwb/engagement/:pursuitId/assessmentdetailspopup',
  GET_PURSUIT_GREEN_CLOUD_DATA: 'cwb/engagement/:pursuitId/greencloud',

  //DCA Engagement Specific
  GET_ENG_S3_FILES: 'dca/project/:projectId/s3files ',
  LIST_ENG_LANDING_ZONES: 'dca/project/:projectId/landingzone',

  //DCA pursuit Specific
  GET_PURSUIT_S3_FILES: 'dca/engagement/:pursuitId/s3files ',
  LIST_PURSUIT_LANDING_ZONES: 'dca/engagement/:pursuitId/landingzone',

  // OperateEdge
  GET_ENG_OPERATE_EDGE_INCIDENT_DATA: 'operateedge/project/:projectId/incidentdata',
  GET_PURSUIT_OPERATE_EDGE_INCIDENT_DATA:
    'operateedge/engagement/:pursuitId/incident/getIncidentData',
  GET_ENG_OPERATE_EDGE_ALL_GRAPHS: 'operateedge/project/:projectId/allincident',
  GET_PURSUIT_OPERATE_EDGE_ALL_GRAPHS:
    'operateedge/engagement/:pursuitId/incident/getIncidentAllGraphsData',
  GET_ISSUE_BY_SEVERITY: 'smartsheet',

  GET_SMART_SHEETS: 'smartsheet',
  GET_DOMAIN_LIST: 'capability/domain/metadata',

  //TrunNorth Health check used for VPN check
  GET_HEALTH_CHECK: `/core/api/v1/health`,

  //LearningCenter
  GET_LEARNING_CENTER_SUMMARY_PURSUIT:
    'operateedge/engagement/:pursuitId/report/GetTileAndBreakdownDetails',
  GET_LEARNING_CENTER_SUMMARY_ENG:
    'operateedge/project/:engagementId/report/GetTileAndBreakdownDetails',
  GET_LEARNING_CENTER_COURSE_STATUS_PURSUIT:
    'operateedge/engagement/:pursuitId/report/StatusWiseCoursesUsersStatusCount',
  GET_LEARNING_CENTER_COURSE_STATUS_ENG:
    'operateedge/project/:engagementId/report/StatusWiseCoursesUsersStatusCount',

  // Manage Engagement Feature
  ENGAGEMENT_FEATURES: 'configure/project/features',

  //DEP
  GET_ENG_DEP_: 'dep/project/:projectId',
  GET_PURSUIT_DEP_: 'dep/engagement/:pursuitId',
  GET_ENG_DEP_CODESPACES: 'dep/codespace/project/:projectId',
  GET_PURSUIT_DEP_CODESPACES: 'dep/codespace/engagement/:pursuitId',

  //ProblemIsnights
  GET_ENG_PROBLEM_INSIGHTS_RUN_LIST: 'operateedge/project/:projectId/probleminsights/getdijobqueue',
  GET_PURSUIT_PROBLEM_INSIGHTS_RUN_LIST:
    'operateedge/engagement/:engagementId/probleminsights/getdijobqueue',
  GET_ENG_PROBLEM_INSIGHTS_RUN_DETAILS:
    'operateedge/project/:projectId/probleminsights/getrundetails',
  GET_PURSUIT_PROBLEM_INSIGHTS_RUN_DETAILS:
    'operateedge/engagement/:engagementId/probleminsights/getrundetails',
  GET_ENG_PROBLEM_GROUP_OVERVIEW:
    'operateedge/project/:projectId/probleminsights/getproblemgroupoverview',
  GET_PURSUIT_PROBLEM_GROUP_OVERVIEW:
    'operateedge/engagement/:engagementId/probleminsights/getproblemgroupoverview',

  //KMS
  GET_KMS_APPLICATIONS: 'kms/applications',
  //Transitions Module
  GET_ENG_ACTIVITY_SUMMARY: 'operateedge/project/:engagementId/activity-summary',
  GET_ENG_TRANSITIONS: 'operateedge/project/:engagementId/transitions',
  GET_ENG_RISK_SUMMARY: 'operateedge/project/:engagementId/risk-summary',
  GET_ACTIVITY_SUMMARY: 'operateedge/engagement/:pursuitId/activity-summary',
  GET_TRANSITIONS: 'operateedge/engagement/:pursuitId/transitions',
  GET_RISK_SUMMARY: 'operateedge/engagement/:pursuitId/risk-summary',

  //CBO-2901_AI_AUTO_NEW_CODE : Added new API Routes for AppProgressionData, ServerProgressionData and ArchetypeList for both Pursuit and Engagement Modes
  //
  GET_ENG_ARCHETYPE_LIST: 'migrate/project/:engagementId/architype',
  GET_ARCHETYPE_LIST: 'migrate/engagement/:pursuitId/architype',

  GET_ENG_APP_PROGRESSION: 'migrate/project/:engagementId/app/status',
  GET_ENG_SERVER_PROGRESSION: 'migrate/project/:engagementId/server/status',
  GET_APP_PROGRESSION: 'migrate/engagement/:pursuitId/app/status',
  GET_SERVER_PROGRESSION: 'migrate/engagement/:pursuitId/server/status',

  GET_ENG_APP_PROGRESSION_MONTHLY: 'migrate/project/:engagementId/app/month/status',
  GET_ENG_SERVER_PROGRESSION_MONTHLY: 'migrate/project/:engagementId/server/month/status',
  GET_APP_PROGRESSION_MONTHLY: 'migrate/engagement/:pursuitId/app/month/status',
  GET_SERVER_PROGRESSION_MONTHLY: 'migrate/engagement/:pursuitId/server/month/status',
  //CB360
  GET_ENG_TCO_INSIGHTS: 'cb360/project/:engagementId/tcoinsight',
  GET_TCO_INSIGHTS: 'cb360/engagement/:engagementId/tcoinsight',
  GET_ENG_COST_TREND: 'cb360/project/:engagementId/costtrend',
  GET__COST_TREND: 'cb360/engagement/:engagementId/costtrend',
  GET_ENG_COST_ALLOCATION_SUMMARY: 'cb360/project/:engagementId/costallocationsummary',
  GET_COST_ALLOCATION_SUMMARY: 'cb360/engagement/:engagementId/costallocationsummary',

  GET_ALLOCATION_TREND: 'cb360/engagement/:engagementId/costallocationtrend',
  GET_ENG_ALLOCATION_TREND: 'cb360/project/:engagementId/costallocationtrend',
  GET_CLOUD_COST_SUMMARY_TREND: 'cb360/engagement/:engagementId/costsummarytrend',
  GET_ENG_CLOUD_COST_SUMMARY_TREND: 'cb360/project/:engagementId/costsummarytrend',
  //BlockersandCauses

  GET_ENG_BLOCKER_CAUSES: 'migrate/project/:engagementId/blocker/status',
  GET_BLOCKER_CAUSES: 'migrate/engagement/:pursuitId/blocker/status',
  GET_JOURNEY_MAPPING: 'journey/:journeyId/mapping',
  DELETE_JOURNEY: 'journey/:journeyId',

  GET_ENG_SERVERS_BY_UPGRADE: 'migrate/project/:engagementId/upgrade/status',
  GET_SERVERS_BY_UPGRADE: 'migrate/engagement/:engagementId/upgrade/status',

  GET_USER_ACTIVITY: 'analytics/user/:userMail/activity',
  GET_ANALYTICS_USER_LIST: 'analytics/unique/user',
  GET_PURSUIT_ANALYTICS: 'analytics/active/pursuit',
  GET_DISPOSITIONS_BY_WAVE: 'migrate/engagement/:pursuitId/wave/dispositions',
  GET_ENG_DISPOSITIONS_BY_WAVE: 'migrate/project/:engagementId/wave/dispositions',

  //innowake proxys

  GET_PURSUIT_SQL_DATA: 'innowake/engagement/:projectId/statements/aggregations',
  GET_ENG_SQL_DATA: 'innowake/project/:projectId/statements/aggregations',
  GET_PURSUIT_DECOMPOSITION_GRAPH_DATA: 'innowake/engagement/:projectId/taxonomies',
  GET_ENG_DECOMPOSITION_GRAPH_DATA: 'innowake/project/:projectId/taxonomies',
  GET_PURSUIT_ARTIFACTS_GRAPH_DATA: 'innowake/engagement/:projectId/modules/aggregations',
  GET_ENG_ARTIFACTS_GRAPH_DATA: 'innowake/project/:projectId/modules/aggregations',
  POST_PURSUIT_CODE_QUALITY: 'innowake/engagement/:projectId/modules/aggregations',
  POST_ENG_CODE_QUALITY: 'innowake/project/:projectId/modules/aggregations',

  // TruNorthProxies

  GET_ENG_APPLICATION_DISPOSITIONING: 'trunorth/project/:projectId/report/business-applications',
  GET_PURSUIT_APPLICATION_DISPOSITIONING:
    'trunorth/engagement/:projectId/report/business-applications',
  GET_ENG_SURVEY_METRICS_INVENTORY: 'trunorth/project/:projectId/report/summary',
  GET_PURSUIT_SURVEY_METRICS_INVENTORY: 'trunorth/engagement/:projectId/report/summary',
  GET_ENG_SURVEY_METRICS_BUSINESS:
    'trunorth/project/:projectId/report/business-applications/by-line-of-business',
  GET_PURSUIT_SURVEY_METRICS_BUSINESS:
    'trunorth/engagement/:projectId/report/business-applications/by-line-of-business',
  GET_ENG_DISCOVERY_METRICS: 'trunorth/project/:projectId/report/portfolio-summary',
  GET_PURSUIT_DISCOVERY_METRICS: 'trunorth/engagement/:projectId/report/portfolio-summary',
  GET_ENG_WAVE_PLAN: 'trunorth/project/:projectId/report/wave-plan',
  GET_PURSUIT_WAVE_PLAN: 'trunorth/engagement/:projectId/report/wave-plan',

  // Digital Tester Proxies
  GET_ENG_WORKFLOWS_LIST: 'digital-tester/project/:projectId/workflow-details',
  GET_PURSUIT_WORKFLOWS_LIST: 'digital-tester/engagement/:projectId/workflow-details',
  GET_ENG_WORKFLOW_METRICS: 'digital-tester/project/:projectId/metrics-details/:workflowId',
  GET_PURSUIT_WORKFLOW_METRICS: 'digital-tester/engagement/:projectId/metrics-details/:workflowId',
  GET_ENG_WORKFLOW_REPORTS: 'digital-tester/project/:projectId/execution-details/:workflowId',
  GET_PURSUIT_WORKFLOW_REPORTS:
    'digital-tester/engagement/:projectId/execution-details/:workflowId',

  //Turbocode proxies
  GET_ENG_TURBOCODE_STORIES: 'turbocode/project/:projectId/code-workshop/stories',
  GET_PURSUIT_TURBOCODE_STORIES: 'turbocode/engagement/:projectId/code-workshop/stories',

  //TalkQE Proxy
  GET_DEFECT_AGEING: 'talkqe/engagement/:projectId/defect-ageing',
  GET_NUMBER_OF_TEST_CASES: 'talkqe/engagement/:projectId/test-case-view',
  GET_SEVERITY_VS_CREATED_ON: 'talkqe/engagement/:projectId/severity-vs-created-on',
  GET_TEST_RUN_STATUS: 'talkqe/engagement/:projectId/test-run-status',
  GET_TABLE_COUNT: 'talkqe/engagement/:projectId/table-count',
  GET_ENG_DEFECT_AGEING: 'talkqe/project/:projectId/defect-ageing',
  GET_ENG_NUMBER_OF_TEST_CASES: 'talkqe/project/:projectId/test-case-view',
  GET_ENG_SEVERITY_VS_CREATED_ON: 'talkqe/project/:projectId/severity-vs-created-on',
  GET_ENG_TEST_RUN_STATUS: 'talkqe/project/:projectId/test-run-status',
  GET_ENG_TABLE_COUNT: 'talkqe/project/:projectId/table-count',

  //Web Analytics pursuits & Engagements Reports
  GET_PERIODWISE_PURSUITS_DATA: 'pursuit/report',
  GET_PERIODWISE_ENGAGEMENTS_DATA: 'engagement/report',
  GET_JOURNEYWISE_PURSUITS_ENG: 'journey/report',
  GET_OVERALL_SUMMARY_STATUS_REPORT: 'analytics/summary',
  GET_PURSUITS_ACCUMULATIVE_REPORT: 'analytics/pursuit/report',
  GET_ENGAGEMENTS_ACCUMULATIVE_REPORT: 'analytics/engagement/report',
  GET_FISCAL_YEARS_META_DATA: 'analytics/metadata',
  GET_PURSUIT_REPORTS_TABLE: 'analytics/pursuit/detailed-summary',
  GET_ENGAGEMENT_REPORTS_TABLE: 'analytics/engagement/detailed-summary',

  //TruNorth Decompose
  GET_ENG_TAXONOMY_EDITOR: 'trunorth-decompose/project/:engagementId/taxonomy',
  GET_PURSUIT_TAXONOMY_EDITOR: 'trunorth-decompose/engagement/:pursuitId/taxonomy',
  GET_PURSUIT_LEGACY_VIEW: 'trunorth-decompose/engagement/:projectId/legacy-db-details',
  GET_ENG_LEGACY_VIEW: 'trunorth-decompose/project/:projectId/legacy-db-details',
  GET_PURSUIT_LEGACY_GRAPH: 'trunorth-decompose/engagement/:projectId/legacy-db-graph-model',
  GET_ENG_LEGACY_GRAPH: 'trunorth-decompose/project/:projectId/legacy-db-graph-model',

  //eng closure date
  POST_ENGAGEMENT_CLOSURE_DATE: 'close/project/:projectId',

  //smartstart

  GET_SMARTSTART_MOF_DETAILS: 'asset/:assetId/mof-tags',
  GET_SMARTSTART: 'assets',
  GET_ALL_SMARTSTART: 'assets/export',
  ADD_SMART_START: 'asset',
  UPDATE_SMART_START: 'asset',
  ADD_SMART_START_MOF: 'asset/mof-tags',
  GET_SMARTSTART_CATEGORIES: 'asset/category',
  DELETE_SMART_START: 'asset/:assetId',
  GET_SMARTSTART_TAGS: 'asset/tags',
};
