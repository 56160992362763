export const AUTH_CODE_PARAM_KEY = 'code';
export const REDIRECT_URI_PARAM_KEY = 'redirect_uri';

export enum AuthorizableAsset {
  CWB = 'cwb',
  CMS = 'cms',
  Assess = 'assess',
}

export const AuthenticationPageLabels = {
  authenticating: 'Authenticating ...',
  blockTitle: 'Your Account is Blocked',
  blockSubtitle: 'Please contact admin to access this page.',
  notCreatedTitle: 'Access Denied',
  notCreatedSubTitle: 'You don’t have permission to access this site.',
  goToLogin: 'Go To Login',
  notCreated: 'notCreated',
  authenticatePlaceHolder: ' ',
};
export const RBACMapping = {
  'engagementDashboard': 'ENGAGEMENT_DASHBOARD',
  'engagementSummary': 'ENGAGEMENT_SUMMARY',
  'engagementMarketplace': 'ENGAGEMENT_MARKETPLACE',
  'marketplaceSearch': 'MARKETPLACE_SEARCH',
  'authorizeAsset': 'AUTHORIZE_ASSET',
  'engCofig': 'ENGAGEMENT_JOURNEY_ADD_EDIT',
  'clientEngagementView': 'CLIENT_ENGAGEMENT_VIEW',
  'clientAddEdit': 'CLIENT_ADD_EDIT',
  'clientDelete': 'CLIENT_DELETE',
  'engagementDelete': 'ENGAGEMENT_DELETE',
  'viewUser': 'USER_VIEW',
  'viewUserClientMapping': 'USER_CLIENT_MAPPING',
  'userAddEdit': 'USER_ADD_EDIT',
  'userDelete': 'USER_DELETE',
  'reports': 'WEB_ANALYTICS',
  'engagementAddEdit': 'ENGAGEMENT_ADD_EDIT',
  'salesEngagementSummary': 'SALES_ENGAGEMENT_SUMMARY',
  'productTour': 'PRODUCT_TOUR',
  'journeyList': 'JOURNEY_LIST',
  'journeyView': 'JOURNEY_VIEW',
  'journeyAddEdit': 'JOURNEY_ADD_EDIT',
  'journeyApproveReject': 'JOURNEY_APPROVE_REJECT',
  'capabilityList': 'CAPABILITY_LIST',
  'capabilityAddEdit': 'CAPABILITY_ADD_EDIT',
  'feedback': 'FEEDBACK',
  'pursuitStatusUpdate': 'PURSUIT_STATUS_UPDATE',
  'marketplaceSmartStart': 'SMARTSTART',
  'configureKeyAsset': 'CONFIGURE_KEYASSET',
  'featureAddEdit': 'FEATURE_ADD_EDIT',
  'allFeedbackList': 'ALL_FEEDBACK_LIST',
  'closeEngagementDate': 'CLOSE_ENGAGEMENT_DATE',
  'smartStart': 'SMARTSTART_MANAGEMENT',
  'pursuitsEngagementsReport': 'PURSUITS_ENGAGEMENTS_REPORT',
};
