import { put, takeEvery } from 'redux-saga/effects';
import TrafficAnalyticsService from '../../../services/TrafficAnalyticsService/TrafficAnalyticsService';
import {
  IMostVisitedCapabilitiesResponse,
  IVisitedPagePayload,
  TrafficAnalyticsAction,
  TrafficAnalyticsActions,
  TrafficAnalyticsActionTypes,
  ICapabilitiesByPhaseResponse,
  IPursuitVsEngageemntPayload,
} from '../../actions/WebAnalytics/TrafficAnalyticsActions';
import { storage, STORAGE_CONSTANTS } from '../../../services/LocalStorage';
import {
  IAnalyticsUserListResponse,
  IEngagementKeyAssetsData,
  IFiscalYear,
  IOverallSummaryResponseData,
  IPursuitAnalyticsResponse,
  IPursuitEngagementResponse,
  IPursuitEngReportsPayload,
  IPursuitsEngagementAccumulativePayload,
  IPursuitsEngagementAccumulativeResponse,
  IPursuitsEngagementsOverallSummaryResponse,
  IPursuitsEngReportsResponse,
  IUserActivityListResponse,
  IUserActivityResponse,
  IUserAnalyticsPayload,
} from '../../../models/cbo/cboWebAnalyticsModel';

export function* postVisitedDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IVisitedPagePayload;
    data.activityId = storage.getItem(STORAGE_CONSTANTS.sessionToken) ?? '';
    data.pageType = data?.pageType ? data?.pageType : '';
    const userDetails = JSON.parse(storage.getItem(STORAGE_CONSTANTS.userDetails) || '');
    data.user_name = userDetails?.name;
    yield TrafficAnalyticsService.postVisitedMenu(data)!;
    yield put(TrafficAnalyticsActions.postVisitedPageSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.postVisitedPageError(error));
    }
  }
}

export function* getMostVisitedCapabilitiesDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as string;
    const response: IMostVisitedCapabilitiesResponse[] =
      (yield TrafficAnalyticsService.getMostVisitedCapabilitiesPerPhase(data))!;
    yield put(TrafficAnalyticsActions.getMostVisitedCapabilitiesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getMostVisitedCapabilitiesError(error));
    }
  }
}

export function* getCapabilitiesByPhaseDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as string;
    const response: ICapabilitiesByPhaseResponse[] =
      (yield TrafficAnalyticsService.getCapabilitiesByPhase(data))!;
    yield put(TrafficAnalyticsActions.getCapabilitiesByPhaseSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getCapabilitiesByPhasError(error));
    }
  }
}

export function* getKeyAssetsCountDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as string;
    const response: IEngagementKeyAssetsData[] =
      (yield TrafficAnalyticsService.getKeyAssetsCount(data))!;
    yield put(TrafficAnalyticsActions.getKeyAssetsCountSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getKeyAssetsCountError(error));
    }
  }
}

export function* getPursuitsEngagementDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IPursuitVsEngageemntPayload;
    const response: IPursuitEngagementResponse =
      (yield TrafficAnalyticsService.getPursuitEngegementsData(data))!;
    yield put(TrafficAnalyticsActions.getPursuitEngagementsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getPursuitEngagementsDataError(error));
    }
  }
}

//CBO-3309:generated sagas for user activity and user list
export function* getAnalyticsUserListDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IUserAnalyticsPayload;
    const response: IAnalyticsUserListResponse =
      (yield TrafficAnalyticsService.getAnalyticsUserListService(data))!;
    if (response) {
      if (data?.isExport) {
        yield put(
          TrafficAnalyticsActions.getAnalyticsUserListSuccess({
            ...response,
            isExport: true,
          } as IAnalyticsUserListResponse),
        );
      } else {
        yield put(TrafficAnalyticsActions.getAnalyticsUserListSuccess(response));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getAnalyticsUserListError(error));
    }
  }
}
export function* getUserActivityDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IUserAnalyticsPayload;
    const response: IUserActivityListResponse =
      (yield TrafficAnalyticsService.getUserActivityService(data))!;
    yield put(TrafficAnalyticsActions.getUserActivitySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getUserActivityError(error));
    }
  }
}

export function* getPursuitAnalyticsDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IUserAnalyticsPayload;
    const response: IPursuitAnalyticsResponse =
      (yield TrafficAnalyticsService.getPursuitAnalyticsService(data))!;
    if (response) {
      if (data?.isExport) {
        yield put(
          TrafficAnalyticsActions.getActivePursuitsAnalyticsSuccess({
            ...response,
            isExport: true,
          } as IPursuitAnalyticsResponse),
        );
      } else {
        yield put(TrafficAnalyticsActions.getActivePursuitsAnalyticsSuccess(response));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getActivePursuitsAnalyticsError(error));
    }
  }
}

export function* getPursuitEngOverallSummaryDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as string;
    const response: IOverallSummaryResponseData[] =
      (yield TrafficAnalyticsService.getPursuitEngOverallSummaryService(data))!;
    if (response) {
      yield put(
        TrafficAnalyticsActions.getPursuitEngOverallSummarySuccess({
          overallSummaryData: response,
          reportType: data,
        } as IPursuitsEngagementsOverallSummaryResponse),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getActivePursuitsAnalyticsError(error));
    }
  }
}

export function* getPursuitsAccumulativeDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IPursuitsEngagementAccumulativePayload;
    const response: IPursuitsEngagementAccumulativeResponse[] =
      (yield TrafficAnalyticsService.getPursuitAccumulativeDataService(data))!;
    if (response) {
      yield put(TrafficAnalyticsActions.getPursuitAccumulativeDataSuccess(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getActivePursuitsAnalyticsError(error));
    }
  }
}

export function* getEngagementsAccumulativeDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IPursuitsEngagementAccumulativePayload;
    const response: IPursuitsEngagementAccumulativeResponse[] =
      (yield TrafficAnalyticsService.getEngagementAccumulativeDataService(data))!;
    if (response) {
      yield put(TrafficAnalyticsActions.getEngagementAccumulativeDataSuccess(response));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getActivePursuitsAnalyticsError(error));
    }
  }
}

export function* getFiscalYearMetaDataWorker(action: TrafficAnalyticsAction) {
  try {
    const response: IFiscalYear[] = (yield TrafficAnalyticsService.getFiscalYearMetaDataService())!;
    yield put(TrafficAnalyticsActions.getFiscalYearsMetadataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getFiscalYearsMetadataError(error));
    }
  }
}

export function* getPursuitEngReportsTableDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IPursuitEngReportsPayload;
    const response: IPursuitsEngReportsResponse =
      yield TrafficAnalyticsService.getPursuitEngReportsTableDataService(data);
    yield put(TrafficAnalyticsActions.getPursuitEngReportsTableSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getPursuitEngReportsTableError(error));
    }
  }
}

export function* trafficAnalyticsWatcher() {
  yield takeEvery(
    TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_START,
    postVisitedDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_CAPABILITIES_BY_PHASE_START,
    getCapabilitiesByPhaseDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_MOST_VISITED_CAPABILITIES_START,
    getMostVisitedCapabilitiesDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_PURSUIT_ENGAGEMENT_START,
    getPursuitsEngagementDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_KEY_ASSETS_COUNT_START,
    getKeyAssetsCountDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_ANALYTICS_USER_LIST_START,
    getAnalyticsUserListDataWorker,
  );
  yield takeEvery(TrafficAnalyticsActionTypes.GET_USER_ACTIVITY_START, getUserActivityDataWorker);
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_ACTIVE_PURSUITS_ANALYTICS_START,
    getPursuitAnalyticsDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_OVERALL_SUMMARY_STATUS_REPORT_START,
    getPursuitEngOverallSummaryDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_PURSUIT_ACCUMULATIVE_DATA_START,
    getPursuitsAccumulativeDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_ENGAGEMENT_ACCUMULATIVE_DATA_START,
    getEngagementsAccumulativeDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_FISCAL_YEARS_METADATA_START,
    getFiscalYearMetaDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_PURSUIT_ENG_REPORTS_TABLE_START,
    getPursuitEngReportsTableDataWorker,
  );
}
